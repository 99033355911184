// Hero

.referral-hero {
  padding-top: 32px;
  z-index: 1;
  background-color: $white;
  margin-bottom: 16px;
  overflow: hidden;

  .container {
    padding-bottom: 0px !important;
  }

  .register-button-wrapper {
    padding-bottom: 48px;
  }

  @include media("<desktop") {
    padding-top: 0px;
  }
}

.referral-hero-heading {
  margin-bottom: 24px;

  @include media("<tablet") {
    padding-right: 24px;
  }
}

.referral-description {
  margin-bottom: 24px;
  //max-width: 540px;

  @include media("<tablet") {
    //max-width: unset;
    padding-right: 24px;
  }
}

.referral-hero-card {
  overflow: visible;
  padding: 4 * $ui-space-base;
}

// Highlights

.referral-highlights {
  position: relative;
  z-index: 1;
}

.referral-highlights-border-top {
  border-top: $ui-border-width solid $ui-border-color;
}

.referral-highlights-grid {
  display: flex;
  flex-wrap: wrap;
  border-bottom: $ui-border-width solid $ui-border-color;
}

.referral-highlight-feature {
  display: flex;
  align-items: flex-start;
  padding: 24px;

  &:not(:last-child) {
    border-right: $ui-border-width solid $ui-border-color;
  }

  @include media(">bmd") {
    width: calc(100% / 3);
    border-right: 0;
  }

  h3 {
    margin-bottom: 8px;
  }
}

.highlight-icon {
  width: 80px;
  height: 64px;
  padding-right: 16px;
}

.referral-highlights-logged {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 24px;
}

.referral-highlights-signup {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 32px;
  padding-bottom: 48px;
}


// FAQ

.referral-faq-item {
  border-bottom: $ui-border-width solid $ui-border-color;
  align-items: flex-start;

  .referral-faq-question {
    display: flex;
    padding: 20px 24px;
  }

  .referral-faq-answer {
    display: none;
    padding: 0 24px 20px;
  }

  &.open .referral-faq-answer {
    display: flex;
  }

  .referral-faq-icon {
    flex-basis: 32px;
    flex-shrink: 0;
    color: $text-secondary;
  }

  .referral-faq-body {
    flex-grow: 1;

    .text-block {
      :last-child {
        margin-bottom: 0px;
      }
    }
  }
}

.referral-id-box {
  display: flex;
  background-color: #FFFFFF;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 4px;
  width: 313px;
  height: 48px;
  padding-left: 16px;
  margin-right: 16px;

  @include media("<tablet") {
    width: 250px;
  }

  .referral-label-value {
    padding-top: 6px;
    flex-grow: 5;
  }

  .referral-copy {
    display: flex;
    align-items: center;
    margin-right: 12px;
  }
}

.referrral-wrapper {
  padding: 4px 32px !important;
}

.referral-display-block {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin-top: auto;

  >* {
    margin-bottom: 16px;
  }

  @media (min-width: 1300px) {
    display: flex;
    flex-wrap: nowrap;

    >* {
      margin-bottom: 0px;
    }
  }
}

.mobile-display-btn {
  display: flex;

  div {
    margin-right: 8px;

    :last-child {
      margin-right: 0px;
    }
  }
}

.referral-label {
  font-size: 12px;
  letter-spacing: 0.21px;
  line-height: 16px;
  text-align: left;
}

.referral-value {
  font-size: 16px;
  line-height: 16px;
}

.btn-background {
  background-color: #FFFFFF;
  border: 1px solid rgba(91, 16, 255, 0.08);
  border-radius: 4px;
  width: max-content;
  height: 48px;
}

.referral-page-pt-5 {
  padding-top: 5% !important;

  @media (min-width: 768px) {
    padding-top: 5%;
  }
}

.gr-referral {
  border-bottom: 1px solid #ccc;
  padding: $ui-space-base * 3;
  display: grid;
  grid-template-columns: minmax(60px, 120px) minmax(0, 200px) minmax(100px, 120px) minmax(200px, 1fr) minmax(140px, 0.75fr) minmax(0px, 120px);
  grid-template-rows: 64px;
  font-size: 16px;

  .gh {
    padding-left: 16px;
    padding-right: 16px;
  }

  &:last-child {
    border: none;
  }

  @include media("<desktop") {
    display: flex;
    height: auto;
    flex-direction: column;
    flex-wrap: wrap;
  }

  .referral-table-coin-name {
    color: black;
    font-weight: 500;
    font-size: 16px !important;
  }
}

.referral-stats-box {
  box-shadow: inset 0px -1px 0 0 rgba(0, 0, 0, 0.2);
  width: 100%;
  margin-left: 14px;
  margin-right: 14px;
  padding: 16px;
  display: flex;
  flex-direction: column;

  @media (min-width: 1200px) {
    &.stats-box-small {
      width: 15%;
    }

    box-shadow: inset -1px 0 0 0 rgba(0, 0, 0, 0.2);
    width: calc(70% /3);
    margin-left: auto;
    margin-right: auto;
  }
}

.referral-stats-header {
  font-size: 16px;
  line-height: 24px;
  font-weight: bold;
  letter-spacing: 0.15px;
  text-align: center;
  margin-bottom: auto;
}

.referral-stats-value {
  padding-top: 16px;
  font-size: 24px;
  line-height: 24px;
  font-weight: bold;
  letter-spacing: 0.15px;
  text-align: center;

  span {
    font-size: 24px;
    color: black;
  }
}

.referral-transactions-header {
  height: 64px;
  padding-top: 20px;
  padding-bottom: 0px;
  padding-left: 24px;
  margin-bottom: 10px;

  @media (min-width: 768px) {
    padding-bottom: 0px;
  }
}

.no-padding {
  padding-top: 5px !important;
  padding-bottom: 5px !important;

  @media (min-width: 768px) {
    padding-top: 0px !important;
    padding-bottom: 0px !important;
  }
}

.mobile-padding-ref-stats {
  @media (max-width: 768px) {
    padding-top: 10px !important;
  }
}

.referral-icon-wrapper {
  width: 20px !important;
  height: 20px !important;
}

.icon-padding {
  padding-left: 5px;
  color: black;
}

.referral-transactions-light-font {
  color: rgba(0, 0, 0, 0.6);
  letter-spacing: 0.15px;
  line-height: 35px;
  font-size: 16px;
  text-align: right;
  margin-left: 4px;

  @media (min-width: 768px) {
    font-size: auto;
  }
}

.hide-ref-col {
  @include media(">desktop") {
    display: none;
  }

  margin-right: auto;
  font-size: 16px;
}

.mobile-icon-padding {
  @media (max-width: 768px) {
    margin-right: 5px;
  }

  line-height: initial;
}

.card-border {
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 8px;
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.04);
}

.no-rewards-message {
  height: 64px;
  display: flex;
  justify-content: center;
  align-items: center;

  span {
    padding: 3 * $ui-space-base;
  }
}

.refferal-detailed-rules {
  .card {
    padding: 5 * $ui-space-base;
  }

  @include media("<tablet") {
    padding: 3 * $ui-space-base ;
  }
}

.rules-modal-buttons {
  display: flex;
  justify-content: flex-end;
  flex-wrap: wrap;

  button {
    margin-bottom: $ui-space-base;
  }

  :first-child {
    margin-right: $ui-space-base;
  }
}

.hero-picture {
  display: flex;
  align-items: flex-end;

  svg {
    max-width: 100%;
    height: auto;
  }

  @include media("<desktop") {
    justify-content: center;
  }
}

.referral-hero-picture {
  >div {
    height: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
  }

  svg {
    @include media("<bxl") {
      display: none;
    }
  }
}

.referral-hero-row {
  margin: 0;

  h1 {
    margin-bottom: 16px;
    font-size: 32px;
    line-height: 40px;
  }

  p {
    margin-bottom: 24px;
    font-size: 16px;
    line-height: 24px;
  }

  .content-col {
    padding: 32px 32px 0 32px;

    @include media(">bxl") {
      padding: 32px 0 32px 114px;
    }
  }
}

.transactions-spinner {
  padding: 32px;
}

.influencer-box {
  box-sizing: border-box;
  background: $lavender;
  border-radius: $ui-space-base;
  .referral-trophy-header {
    padding: 24px 84px 0 84px;
    @include media("<tablet") {
      padding: 76px 24px 0 24px;
    }
    h4 {
      font-weight: $type-weight-bold;
      font-size: $type-lead-size;
      line-height: 26px;
    }
  }

  .referral-stats {
    padding: 0 24px 24px 24px;
  }

  .referral-trophy {
    position: absolute;
    margin-left: 24px;
  }

  .influencer-sprinkles-background {
    position: relative;
    div {
      position: absolute;
      top: 0;
      right: 0;
    }
  }
}
