.cakepool-switch {
  $switch-width: 48px;
  $height: 24px;
  $padding: 4px;
  $transition-time: 0.5s;

  box-sizing: border-box;
  // height: $height;
  padding: $padding;
  position: relative;
  display: flex;
  align-items: center;
  cursor: pointer;

  &:focus {
    outline: solid;
    outline-color: #5B10FF;
    border-radius: '6px';
  }

  .cakepool-switch__switch {
    cursor: pointer;
    width: $switch-width;
    height: $height;
    display: inline-block;
    position: relative;
    margin: 4px;
  }
  .cakepool-switch__track {
    box-sizing: border-box;
    display: inline-block;
    height: $height - $padding * 2;
    width: $switch-width - $padding * 2;
    position: absolute;
    top: $padding;
    left: $padding;
    border-radius: 12px;
    transition: $transition-time;
    animation-timing-function: ease-out;
    box-shadow: $shadow-elevation1;
    font-size: 1rem;
    background-color: #B3B0BB;
    border: 1px solid rgba(0, 0, 0, 0.2);
    opacity: 0.5;
  }
  .cakepool-switch__ball {
    box-sizing: border-box;
    position: absolute;
    top: 0;
    left: 0;
    display: inline-block;
    border-radius: 50%;
    transition: $transition-time;
    height: $height;
    width: $height;
    background-color: white;
    animation-timing-function: ease-out;
    border: 1px solid rgba(0, 0, 0, 0.1);
    box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.24), 0 0 1px 0 rgba(0, 0, 0, 0.12);
  }
  &.cakepool-switch--active {
    .cakepool-switch__ball {
      left: $switch-width - $height;
      background-color: #5B10FF;
      border: none;
    }
    .cakepool-switch__track {
      background-color: rgba(91, 16, 255, 0.48);
    }
  }
  &--disabled {
    cursor: not-allowed;

    .cakepool-switch__ball {
      cursor: not-allowed;
      border: 1px solid rgba(0, 0, 0, 0.1);
    }

    .cakepool-switch__track {
      cursor: not-allowed;
      background: rgba(179, 176, 187, 0.5);
      border: 1px solid rgba(0, 0, 0, 0.1);
    }
  }
}
