.error-page {
  padding: 5 * $ui-space-base;
  text-align: center;
}

.typing-cat {
  position: relative;
  .text {
    position: absolute;
    left: 60px;
    top: 20px;
    color: #B3B0BB;
    width: 208px;
    font-size: 9px;
    font-family: monospace;
    font-weight: bold;
    line-height: 12px;
  }
  .typed {
    border-right: 4px solid #B3B0BB;
    white-space: nowrap;
    overflow: hidden;
    animation: type 2s steps(40) 1s alternate infinite,
               blink 500ms infinite;
  }
  @keyframes type {
    from { width: 0; }
    to { width: 24em; }
  }
  @keyframes blink {
    0%   { border-right-color: #B3B0BB; }
    50%  { border-right-color: #B3B0BB; }
    51%  { border-right-color: transparent; }
    100% { border-right-color: transparent; }
  }

  @keyframes pawr-typing {
    0%   { transform: translate(3%, -5%); }
    50%  { transform: translate(0, 0); }
    100% { transform: translate(3%, -5%); }
  }
  @keyframes pawl-typing {
    0%   { transform: translate(0, 0); }
    50%  { transform: translate(-3%, -5%); }
    100% { transform: translate(0, 0); }
  }
  #pawr {
    animation: pawr-typing 0.3s infinite;
  }
  #pawl {
    animation: pawl-typing 0.3s infinite;
  }
}



.typing-cat-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}

.social-links {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 10 * $ui-space-base;

  div:first-child {
    margin-left: 0px;
  }

  a {
    margin-left: 1.5 * $ui-space-base;
    margin-right: 1.5 * $ui-space-base;
  }
}
