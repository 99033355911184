.sprods-wrapper {
  :last-child() {
    margin-bottom: 0px;
  }

  @media (min-width: 1200px) {
    display: grid;
    grid-template: repeat(1, 1fr) / repeat(2, 1fr);
    gap: 32px;
  }
}

.sprod {
  width: 100%;
  border: $ui-border-width solid $ui-border-color;
  border-radius: $ui-border-radius;
  background-color: #ffffff;
  padding: 25px;

  &.xmas {
    background-color: #FAF6E7;
  }

  @media (max-width: 1200px) {
    margin-bottom: 25px;
  }

  .xmas-snow {
    position: absolute;
    top: 0;
    left: 0;
    pointer-events: none;
    width: 100%;
    height: 50%;

    background: url("../../../assets/svg/xmas-background.svg");
    background-repeat: no-repeat;
    background-size: cover;
  }

  h3 {
    margin-bottom: 0;
  }

  .coin-icon {
    transform: scale(1.5);
    width: 48px !important;
    height: 48px !important;
    margin-left: 8px;

    &.xmas {
      transform: scale(1.9);
      width: 54px !important;
      height: 54px !important;
      margin-top: -12px;
    }

    @include media("<tablet") {
      margin-right: 8px;
    }
  }

  .lapis-header {
    display: flex;
    justify-content: space-between;
    min-height: 96px;

    &.xmas {
      z-index: 1;
    }
  }

  .lapis-body {
    margin-top: 2 * $ui-space-base;

    .h2 {
      margin-bottom: 0;
      font-size: 16px;

      &.xmas {
        color: #ffffff;
        background-color: #D4AF37;
        border-radius: 8px;
        padding-left: 8px;
        padding-right: 8px;
      }
    }
  }

  .lapis-footer {
    margin-top: 2 * $ui-space-base;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .my-entry-wrapper-desktop {
    display: flex;
    align-items: center;

    button {
      margin-right: 2 * $ui-space-base;
    }

    span {
      color: $text-secondary;

      @media (max-width: 640px) {
        display: none;
      }
    }
  }

  .my-entry-wrapper-mobile {
    display: none;

    @media (max-width: 640px) {
      display: block;
      margin-top: 2 * $ui-space-base;
    }
  }
}

.sprod-entry-modal {
  .big-coin-icon {
    transform: scale(1.5);
    margin-top: 2 * $ui-space-base;
  }

  .entry-modal-details-wrapper {
    padding: 2 * $ui-space-base;

    .entry-modal-detail {
      padding: 2 * $ui-space-base;
      border-top: 1px solid $ui-border-color-light;
      border-bottom: 1px solid $ui-border-color-light;
      display: flex;
      justify-content: space-between;

      :first-child {
        color: rgba(0, 0, 0, 0.6);
      }
    }

    .sale-closes-on-date {
      border-left: 1px solid $ui-border-color-light;
      border-right: 1px solid $ui-border-color-light;
      border-radius: $ui-border-radius 0 0 $ui-border-radius;

      @media (max-width: 763px) {
        border-radius: $ui-border-radius;
      }
    }

    .batch-matures-on-date {
      border-right: 1px solid $ui-border-color-light;
      border-radius: 0 $ui-border-radius $ui-border-radius 0;

      @media (max-width: 763px) {
        margin-top: 5px;
        border-left: 1px solid $ui-border-color-light;
        border-radius: $ui-border-radius;
      }
    }

    .entry-fee {
      border-top: 0 solid $ui-border-color-light;
      border-left: 1px solid $ui-border-color-light;
      border-right: 1px solid $ui-border-color-light;
      border-radius: 0 0 $ui-border-radius $ui-border-radius;

      @media (max-width: 763px) {
        margin-top: 5px;
        border-top: 1px solid $ui-border-color-light;
        border-radius: $ui-border-radius;
      }
    }
  }

  .entry-modal-footer {
    background-color: #f4f3f7;
    padding: 40px 20px 40px 20px;
    left: 2 * $ui-space-base;
    position: absolute;
    bottom: 0;
    width: 100%;
    min-height: 128px;
    align-items: center;

    @media (max-width: 763px) {
      font-size: 11px;
    }

    .wallet-balance {
      font-weight: bold;
    }

    .entry-modal-cta-buttons {
      display: flex;
      justify-content: flex-end;

      :first-child {
        margin-right: 5px;
      }

      @media (max-width: 450px) {
        display: block;

        :first-child {
          margin-right: 0 !important;
          margin-bottom: 5px;
        }

        button {
          width: 100%;
        }
      }
    }
  }
}

.auto-compound-settings {
  padding: 2 * $ui-space-base;
  margin-bottom: 125px;
}

.cancel-confirmation-footer {
  button {
    margin-left: 2 * $ui-space-base !important;
    margin-top: 100px;

    @media (max-width: 560px) {
      margin-top: 50px;
    }
  }
}

.sprod-auto-compound {
  border-left: 1px solid $ui-border-color-light;
  border-top: 1px solid $ui-border-color-light;
  border-radius: $ui-border-radius 0 0 0;
  color: rgba(0, 0, 0, 0.6);
  padding-top: $ui-space-base * 2;
  padding-bottom: $ui-space-base * 2;

  @media (max-width: 767px) {
    border-right: 1px solid $ui-border-color-light;
    border-bottom: 0px solid $ui-border-color-light;
    border-radius: $ui-border-radius $ui-border-radius 0 0;
  }

  h3 {
    color: $black;
    margin-bottom: $ui-space-base * 3;
    font-weight: 500;
  }
}

.compound-preference-info-text {
  color: rgba(0, 0, 0, 0.6);
  border-left: 1px solid $ui-border-color-light;
  border-right: 1px solid $ui-border-color-light;
  border-bottom: 1px solid $ui-border-color-light;
  border-radius: 0px 0px $ui-border-radius $ui-border-radius;

  > div {
    padding-bottom: 2 * $ui-space-base;
  }
}

.sprod-compound-settings {
  border-right: 1px solid $ui-border-color-light;
  border-top: 1px solid $ui-border-color-light;
  border-radius: 0 $ui-border-radius 0 0;
  color: rgba(0, 0, 0, 0.6);
  padding-top: $ui-space-base * 2;
  padding-bottom: $ui-space-base * 2;

  @media (max-width: 767px) {
    //margin-top: 5px;
    border-left: 1px solid $ui-border-color-light;
    border-top: 0px solid $ui-border-color-light;
    border-radius: 0px;
  }

  .sprod-compound-settings-option {
    display: flex;
    align-items: center;
    margin-bottom: 16px;
    padding-right: 16px;

    div {
      margin: 0;
    }
  }

  label {
    margin: 0px;
  }
}

.mb-32 {
  margin-bottom: $ui-space-base * 4;
}

.no-batches-available {
  width: 100%;
  height: 56px;
}

.batch-cap-progress-bar {
  border: 0.5px solid rgba(0, 0, 0, 0.16);
  border-radius: 10px;
  width: 64px;
  height: 14px;
  margin-left: $ui-space-base;

  div {
    background-color: #6400ff;
  }
}

.progress-bar-preview {
  width: 32px;
  margin: 0px $ui-space-base 0px 0px;
}

.tooltip-wrapper {
  padding: $ui-space-base 2 * $ui-space-base;
}

.dotted {
  background-image: url("../../../assets/svg/texture-dotted.svg") !important;
}

.deposit-section {
  padding: 2 * $ui-space-base;

  .deposit-address {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 2 * $ui-space-base;
    border-radius: $ui-border-radius 0 0 $ui-border-radius;
    border-left: 1px solid $ui-border-color-light;
    border-top: 1px solid $ui-border-color-light;
    border-bottom: 1px solid $ui-border-color-light;

    @media (max-width: 763px) {
      //margin-top: 5px;
      border-left: 1px solid $ui-border-color-light;
      border-right: 1px solid $ui-border-color-light;
      border-top: 1px solid $ui-border-color-light;
      border-bottom: 0px;
      border-radius: $ui-border-radius $ui-border-radius 0 0;
    }
  }

  .qr-code {
    display: flex;
    justify-content: flex-end;
    padding: 2 * $ui-space-base;
    border-radius: 0 $ui-border-radius $ui-border-radius 0;
    border-right: 1px solid $ui-border-color-light;
    border-top: 1px solid $ui-border-color-light;
    border-bottom: 1px solid $ui-border-color-light;

    .qrcode {
      margin: 0px;
      display: flex;
    }

    @media (max-width: 763px) {
      border-left: 1px solid $ui-border-color-light;
      border-right: 1px solid $ui-border-color-light;
      border-bottom: 1px solid $ui-border-color-light;
      border-top: 0px;
      border-radius: 0 0 $ui-border-radius $ui-border-radius;
      justify-content: flex-start;
    }
  }

  .deposit-header {
    display: flex;
    flex-direction: column;

    .heading {
      margin-bottom: 0.5 * $ui-space-base;

      @media (max-width: 763px) {
        margin-bottom: $ui-space-base;
      }
    }

    .address {
      word-break: break-all;
    }

    .address-and-label {
      @media (max-width: 763px) {
        display: flex;
        flex-direction: column;
      }
    }

    .address-wrapper {
      display: flex;
      align-items: baseline;

      @media (max-width: 763px) {
        align-items: center;
      }
    }

    @media (max-width: 763px) {
      margin-bottom: 2 * $ui-space-base;
    }
  }

  .cakepool-switch {
    padding: 0 !important;
  }
}

.content-wrapper {
  margin-bottom: 125px;

  .entry-input {
    .input-group {
      margin-bottom: 2 * $ui-space-base;
    }
  }

  @media (max-width: 763px) {
    margin-bottom: 150px;
  }

  @media (max-width: 650px) {
    margin-bottom: 170px;
  }

  @media (max-width: 450px) {
    margin-bottom: 190px;
  }

  @media (max-width: 400px) {
    margin-bottom: 250px;
  }
}

.pd-16 {
  padding: 2 * $ui-space-base;
}

.pb-8 {
  padding-bottom: $ui-space-base;
}

.commission-notice-wrapper {

  padding-top: 2 * $ui-space-base;

  .commission-notice {
    margin-top: 20px;
    margin-bottom: 20px;
    width: 100%;
  }
}

.compound-preference-menu {
  padding: 2 * $ui-space-base;
  margin-top: 2 * $ui-space-base;
}

.bonus-explanation-popover {
  display: flex;
  flex-direction: column;
  width: 272px;

  @include media("<tablet") {
    width: 208px;
  }
}

.bonus-explanation-section {
  display: flex;
  flex-direction: column;
  padding: 4px 16px;
  width: 100%;
  border-bottom: $ui-border-width solid $ui-border-color;

  &:last-child {
    border-bottom: 0px;
  }
}

.bonus-explanation-row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 16px !important;
  font-weight: 500;

  > div {
    padding: 4px;

    &:last-child {
      text-align: end;
    }
  }

  &.small-grey {
    color: $grey;
    font-size: 14px !important;
  }
}

.table-container {
  background-color: #F4F3F7;
  border-radius: 8px;
  padding: 16px;
  margin-bottom: 24px;

  .table-wrapper {
    margin-bottom: 8px;
    border: 1px solid #D1CDDB;
    border-radius: 8px;
  }
  
  .table-style {
    border-radius: 8px;
    overflow: hidden
  }
}