.main-layout {
  $header-height: 80px;
  $background-color: $cardboard;

  font-family: "IBM Plex Sans", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif,
    "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-weight: 400;
  letter-spacing: 0em;
  line-height: 2.4rem;
  background-color: $background-color;
  // min-height: 100vh;
  box-sizing: border-box;
  &__global-alert {
    position: fixed;
    z-index: 2055;

    @include media("<=desktop") {
      bottom: 16px;
    }
    @include media(">desktop") {
      top: 56px;
    }
  }
  & &__header {
    padding: 0px 16px 0px 200px;
    position: absolute;
    top: 0;
    height: $header-height;
    width: 100%;
  }
  & &__header-logo {
    height: 100%;
    width: 200px;
    position: absolute;
    top: 0;
    left: 0;
    padding: 20px;
    cursor: pointer;
  }
  & &__header-link {
    margin: 16px;
    color: $electricberry;
    text-decoration: none;
    cursor: pointer;
  }
  & &__header-link.btn {
    color: $white;
    display: inline-block;
  }
  & &__header-user-menu {
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
  }
  & &__header-link-container {
    height: 100%;
  }
  & &__footer {
    width: 100%;
    padding: 20px;
  }
  & &__header-profile {
    height: 50px;
    width: 50px;
    border-radius: 50%;
    border: 1px solid #333;
    display: inline-block;
    background-color: white;
  }
  & &__header-profile-container {
    display: inline-block;
    margin: 16px;
  }
}
