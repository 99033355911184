.horizontal-option-box {
  border: 1px solid $grey;
  border-radius: 8px;
  display: flex;
  padding: 15px;
  margin: 30px 0 40px 0;
  position: relative;
  min-height: 150px;

  @include media("<desktop") {
    display: block;
  }

  & .button-column {
    @include media(">tablet") {
      display: flex;
      justify-content: flex-end;
    }
  }

  & .column {
    width: 100%;

    & .label {
      font-weight: bold;
    }

    & ul {
      list-style: none;
      padding-left: 15px;
      padding-right: 0;

      @include media("<desktop") {
        list-style: disc;
        padding-left: 20px;
      }
    }

    & .button-wrapper {
      display: flex;
      justify-content: center;

      @media (min-width: 1024px) {
        position: absolute;
        top: 33.3%;
      }

      @include media("<desktop") {
        & button {
          width: 100%;
        }
      }
    }
  }
} 