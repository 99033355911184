.withdrawal-body {
  display: flex;
  justify-content: center;
  align-items: center;

  > div {
    width: 60%;
    @include media("<desktop") {
       width: 100%;
    }
  }

  .withdrawal-address-field {
    .input-group {
      margin: 0px !important;
    }
  }
  

  .email-message {
    text-align: center;

    .mailbox-wrap {
      display: flex;
      justify-content: center;

      .icon__container {
        margin-bottom: 4 * $ui-space-base !important;      
      }
    }
  }
}

.two-fa-message {
  margin-bottom: 2 * $ui-space-base;
}


.withdrawal-header {
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 4px;
  padding: 16px 24px;

  h2 {
    margin: 0px;
    font-size: 22px;
    line-height: 24px;
    display: inline-flex;
    align-items: center;
  }
}

.withdrawal-header-icon {
  margin-left: 4px;
}

.withdrawal-amount {
  display: flex;
  align-items: flex-start;

  .input-group {
    margin-right: 2 * $ui-space-base !important;
    margin-bottom: 3 * $ui-space-base !important;
  }
}

.withdrawal-footer {
  display: flex;
  justify-content: flex-end;
  border-top: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 4px;
  padding: 8px 24px;

  button:nth-child(n+2) {
    margin-left: $ui-space-base;
  }
}

.withdrawal-information {
  margin-bottom: 16px;
  .withdrawal-info-group {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: $ui-space-base * 1.5 0px;
    border-top: 1px solid rgba(0, 0, 0, 0.2);
    border-radius: 4px;
  }

  :first-child {
    border-top: 0px;
  }
}

.withdrawal-legal-checkbox > .cakepool-checkbox {
  margin: 0px;
}

.whitelist-checkbox-and-label {
  display: flex;
  flex-direction: column;
  border-top: 0px;
  padding-top: 1.5 * $ui-space-base;
  padding-bottom: 1.5 * $ui-space-base;
  margin-bottom: 1 * $ui-space-base;

  > div {
    display: flex;
    align-items: center;
    width: 100%;
  }

  .input-group {
    margin: $ui-space-base 0 !important;
  }

  &.whitelisted {
    color: $light-grey;
  }

  .info-icon {
    margin-right: 1 * $ui-space-base;
    margin-top: 0px;
  }
}

.textbox-dropdown {
  position: absolute;
  z-index: 1060;
  width: 100%;
  background-color: white;
  top: 100%;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-top: 0px;
  border-radius: 0px 0px 4px 4px;
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.04);
  .scrollable-content {
    max-height: 250px;
    overflow-y: auto;
  }

  :last-child {
    border-bottom: 0px !important;
  }

  .textbox-dropdown-option {
    display: flex;
    flex-direction: column;
    padding: 1.5* $ui-space-base 2 * $ui-space-base;
    cursor: pointer;
    border-bottom: 1px solid rgba(0, 0, 0, 0.2);

    div {
      width: 100%;
    }

    .address-div {
      margin-bottom: 0.5 * $ui-space-base;
      > span {
        word-break: break-word;
      }

      @include media("<tablet") {
        margin-bottom: $ui-space-base;
      }
    }

    .label-and-last-used {
      display: flex;
      justify-content: space-between;
      align-items: center;

      > span {
        font-size: 14px !important;
      }

      :first-child {
        margin-right: 2 * $ui-space-base;
      }
      
      @include media("<tablet") {
        span {
          width: 100%;
        }
        flex-wrap: wrap;
      }
    }

    &:hover {
      background-color: #eee;
    }
  }
}

.process-time-header-text {
  display: flex;
}

.process-time-text {
  color: $grey;
}

.average-withdrawal {
  display: flex;
  flex-direction: column;
  align-items: end;
  :last-child {
    font-size: 14px;
  }
}

