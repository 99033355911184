.freeze-modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 4px;
  padding: 12px 24px;

  h2 {
    margin: 0px;
    margin-right: 8px;
  }
}

.freeze-modal-body {
  display: flex;
  align-items: center;
  justify-content: center;

  > div {
    width: 50%;

    @include media("<desktop") {
      width: 100%;
    }
  }
}

.freeze-modal-buttons {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  margin-bottom: 16px;
  margin-top: -8px;

  > div {
    padding: 8px;
  }
}

.next-tier-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  padding: 12px 0px;
}

.freeze-modal-tier-change {
  margin: 16px 0px;
  padding: 16px;
  display: flex;
  align-items: center;
  background-color: #F4F3F6;
  border-radius: 4px;
  height: 64px;
  width: 100%;
  color: $grey;
  font-size: 18px;
  text-align: center;

  > div {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
  }

  .current-tier, .tier-afterwards {
    width: 40%;
  }

  .tier-afterwards.tier-changed {
    color: $black;
  }
}

.freeze-modal-arrow-icon {
  width: 20%;
  svg {
    fill: rgba(0, 0, 0, 0.4);
    height: 16px;
    width: 16px;
  }
  path {
    background-color: rgba(0, 0, 0, 0.4);
  }
}

.freeze-modal-footer {
  display: flex;
  justify-content: center;
  align-items: center;
  border-top: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 4px;
  padding: 16px;

  .row {
    width: 100%;
  }
}

.freeze-modal-footer-button {
  display: flex;
  align-items: center;
  justify-content: flex-end;

  @include media("<bmd") {
    margin-top: 16px;
  }
}

.freeze-modal-footer-balance {
  display: flex;
  flex-direction: column;

  @include media("<bmd") {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 8px;
  }

  span  {
    font-size: 16px;
    color: $grey;

    @include media("<bmd") {
      font-size: 18px;
    }

    &:first-child {
      font-size: 14px;
      font-weight: bold;
      color: black;

      @include media("<bmd") {
        font-size: 18px;
      }
    }

    &:last-child() {
      @include media("<bmd") {
        text-align: right;
      }
    }
  }
}

.freeze-modal-body-loading {
  height: 400px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  > div {
    &:first-child {
      margin-bottom: 32px;
    } 
  }

  .spinner-border {
    width: 32px;
    height: 32px;
    color: $grey;
  }
}

.freeze-modal-table {
  margin-bottom: 3rem;

  td {
    border-bottom: 0.5px solid rgba(0, 0, 0, 0.16);

    &:first-child {
      font-weight: bold;
    }
  }
}