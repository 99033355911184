$color: #ffffff;
$border-color: #eeeeee;
$height: 50px;
.select {
  cursor: pointer;
  position: relative;
  overflow: visible;
  margin-bottom: 32px;
  outline-width: 0;
  &:focus {
    .select__text {
      border: 2px solid #6400ff;
    }
    .label {
      color: #6400ff;
    }
  }
  .select__caret {
    height: $height;
    width: $height;
    float: right;
    text-align: right;
  }
  .select__text {
    height: $height;
    border-radius: 4px;
    padding: 15px;
    border: 1px solid rgba(0,0,0,0.2);
    background-color: $white;
  }
  .select__input {
    width: 70%;
    border: none;
  }
  .select__options {
    position: absolute;
    top: $height;
    background-color: $color;
    overflow: auto;
    min-width: 100%;
    z-index: 5;
    border-radius: $ui-border-radius;
    border: 1px solid $ui-border-color;
    box-shadow: $shadow-elevation1;
  }
  .select__option {
    height: $height;
    width: 100%;
    padding: 12px 12px;
    border-top: 1px solid $border-color;
    white-space: nowrap;
    &:hover {
      background-color: $cardboard;
    }
  }
  .label {
    font-size: 1.4rem;
    font-weight: 500;
    background-color: #fff;
    padding: 0 4px;
    position: absolute;
    left: 13px;
    top: -13px;
    cursor: text;
    -webkit-transition: all 0.2s ease;
    transition: all 0.2s ease;
  }
}
