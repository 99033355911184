.wave-loader {
  height: 32px;
  width: 32px;
}

.wave-loader path {
  animation: moveWave 800ms linear infinite;
}

.wave-loader .w1 {
  stroke-dasharray: 0 26 32 999;
}

.wave-loader .w2 {
  stroke-dasharray: 0 17 32 999;
}

.wave-loader .w3 {
  stroke-dasharray: 0 9 32 999;
}

@keyframes moveWave {
  0% {
    stroke-dashoffset: 0;
    transform: translate3d(0, 0, 0);
  }
  100% {
    stroke-dashoffset: -66.7;
    transform: translate3d(-64px, 0, 0);
  }
}