.preference-options-wrapper {
  width: 66.666666666%;
  margin-top: -8px;

  @include media("<desktop") {
    margin-top: 0px;
  }
}

.tier-with-icon {
  display: flex;
  align-items: center;
  margin-bottom: 16px;
}