.batch-listing {
  margin-top: 10px;
}
  
.batch-info {
  margin-top: 10px;
}

.batch-id-margin {
  margin-bottom: 1.2rem;
}

.batch-status {
  margin-bottom: 3.2rem;
}

.activity-dropdown {
  button {
    background-color: lightgrey !important;
    width: 280px;
  }
}

.dropdown_color {
  background-color: lightgrey !important;
}

.settlement-row-size {
  height: 90px;
}

.trade-modal-row-size {
  height: 250px;
}

.modal-secondary-color, .modal-secondary-color:hover {
  background-color: grey;
}

.modal-loading {
  margin-top: 30px;
}

.update-entry-modal {
  text-align: left;
  padding-left: 25px;
  padding-right: 25px;
}

.cancel-confirmation-modal {
  text-align: center;
  padding-left: 5px;
  padding-top: 100px;
}

.entry-modal-footer {
  button {
    margin-left: 15px;
  }
  .modal-footer-buttons {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
  @include media("<tablet") {
    button {
      margin-left: 0;
    }
    .modal-footer-buttons {
      justify-content: flex-start;
    }
  }
}

.create-batch {
  margin-left:auto;
  margin-right:0;
}

.trade-activity-error {
  font-size: small;
  position: absolute;
  bottom: -20px;
}

.trade-input-error {
  position: relative;
}

.sprod-details {
  overflow: auto;
  margin-bottom: 3 * $ui-space-base;
}

.ongoing-batches-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 4 * $ui-space-base;

  h2 {
    margin: 0px;
  }

  @include media("<tablet") {
    flex-direction: column;
    justify-content: start;
    align-items: flex-start;
    margin: 0;

    h2 {
      margin-bottom: 3 * $ui-space-base;
    }
  }
}

.lapis-filter {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: -16px;

  @include media("<tablet") {
    margin-bottom: 2 * $ui-space-base;
    width: 100%;
    justify-content: flex-end;
  }

  .show {
    margin-right: 2 * $ui-space-base;
  }
}

.expected-apy {
  @include media("<desktop") {
    margin-bottom: $ui-space-base;
  }
}