.header-logo {
  // width: 85px;
  // height: 26px;
  cursor: pointer;
  // color: $electricberry;
  // transition: all 0.3s ease;

  // svg {
  //   fill: currentColor;
  // }

  display: flex;
  align-items: center;
  font-size: 28px;
  line-height: 32px;
  color: #000;
  font-weight: $type-weight-medium;
  letter-spacing: 0.67px;

  &:hover {
    color: inherit !important;
    text-decoration: none !important;
  }

  svg {
    height: 32.1px;
    width: 32.1px;
    background-color: $electricberry;
    border-radius: 4px;
    margin-top: -0.1px;
    margin-left: -0.1px;
    margin-right: 12px;
  }

  .w {
    animation: moveWave 1200ms linear 2;
  }
}

@keyframes moveWave {
  0% {
    transform: translate3d(-72px, 0, 0);
  }
  100% {
    transform: translate3d(-136px, 0, 0);
  }
}

.header {
  display: flex;
  justify-content: space-between;
  padding: 20px 30px !important;
  background: $white;
  @include media("<tablet") {
    padding: 20px 20px !important;
  }

  .header-nav {
    display: flex;
    align-items: center;

    .main-menu {
      margin: 0 20px;

      a, .header-link {
        font-size: 1.6rem;
        font-weight: 500;
        text-decoration: none;
        padding: $ui-space-base * 2;
        letter-spacing: 0;
        color: rgba(0,0,0,.6);
        line-height: 2.4rem;

        &.unset {
          color: #000 !important;
        }
        &:hover {
          color: rgba(0,0,0,.4);
        }
      }
    }
  }

  .header-actions {
    display: flex;
    align-items: center;

    a:hover {
      text-decoration: none;
    }
    &.with-profile {
      // Fix for header-actions>profile in mobile view
      @media screen and (max-width: 460px) {
        position: relative;
        left: -20px;
      }
    }
  }

  .header__icon-container {
    fill: $electricberry;
  }

  .header__language-container {
    margin-left: 8px;
  }

  .header__dropdown-container {
    display: inline-block;
    margin: 16px;
    width: 32px;

    @include media("<tablet") {
      margin: 16px 12px;
    }

    @include media("<281px") {
      margin-left: 0;
    }

    .header__dropdown-avatar {
      width: 40px;
      border-radius: 50%;
      background-image: url("../../../assets/img/default-user-picture@2x.png");
      background-size: 40px;
    }

    .extended-width {
      width: 250px !important;
    }

    .header__mobile-menu {
      width: 200px;
      .header__mobile-menu-container {
        display: flex;
        flex-direction: column;
        padding: 8px 0;
        .header__mobile-menu-link {
          padding: 6px 16px;
          cursor: pointer;
          color: black !important;

          &:hover {
            background: #eee;
            color: black;
            text-decoration: none;
          }
        }
        .header__wallet-summary {
          padding: 0 16px;
          div {
            padding: 5px 0;
            text-align: right;
          }
          .header__wallet-summary-title {
            float: left;
          }
          .header__wallet-summary-icon {
            float: right;
          }
        }
        .header__wallet-summary-total {
          border-top: 1px solid $faded;
          padding: 8px 16px 0px 16px;
          display: flex;
          justify-content: space-between;
        }
      }

      hr {
        color: $text-default;
        border: 1px;
      }
    }
  }

  .header__wallet-summary {
    cursor: pointer;

    &:hover {
      color: initial;
      text-decoration: none;
    }
  }
}
.banner__terms-btn {
  font-size: 16px;
  margin-left: 16px;
}

.profile-dropdown-width {
  width: 280px;
}

.language-dropdown-width {
  width: 150px;
}

.header-vip-badge {
  margin-left: 2 * $ui-space-base;
}

.obfuscate-button {
  background-color: $white;
  color: $text-secondary;
  display: flex;
  height: 30px;
  border: 1px solid $faded;
  border-radius: 5px;
  align-items: center;
  margin-left: 16px;
  &:focus {
    outline: none !important;
  }
}
