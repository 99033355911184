.telephone-input-wrapper {
  margin-bottom: 4rem;
}

.intl-tel-input {
  width: 100%;
}

.intl-tel-input-element {
  padding: 12px 16px;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 4px 4px;
  background-color: #fff;
  outline: none;
}
