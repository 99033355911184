.wallets-page {
  .wallets-page__coin-listing-information {
    margin-bottom: 0;
  }

  .wallets-page__pagination-button {
    display: inline-block;
    height: 32px;
    width: 32px;
    color: $electricberry;
    border-radius: 6px;
    border: 1px solid #efefef;
    margin: 4px;
    text-align: center;
    box-shadow: $shadow-elevation1;
    cursor: pointer;
    fill: $electricberry;

    &.wallets-page__pagination-button--disabled {
      color: #efefef;
      cursor: auto;

      fill: #efefef;
    }
  }

  .coin-listing {
    padding: 16px 32px !important;
    overflow: inherit;
    display: grid;
    .coin-listing-information {
      display: grid;
      grid-template-columns: 80px max-content 50px;
    }

    .coin-listing-amount {
      display: flex;
      margin: 0 !important;
      width: 100% !important;
      font-family: "IBM Plex Sans", "SFMono-Regular", Consolas, "Liberation Mono", Menlo, Courier, monospace !important;
      &__actions {
        display: flex;

        button {
          margin-right: 8px;
        }
      }
    }

    .wallet-balance-shares,
    .wallet-balance-coins {
      padding: 8px !important;
      width: 30%;
    }

    .wallet-balance-returns {
      display: flex;
      align-items: center;
      margin: 0 !important;
      justify-content: flex-end !important;
      width: 20%;
      .sup {
        padding: 0 8px;
      }
    }

    &.coin-listing-balances {
      grid-template-columns: repeat(4, 1fr);
    }

    &.coin-listing-shares {
      grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    }

    @include media("<hd") {
      &.coin-listing-balances {
        grid-template-columns: 1fr 1fr;
        grid-template-rows: repeat(3, 60px);
      }

      grid-template-areas: "balance-info balance-returns" "balance-coins balance-coins" "balance-shares balance-shares";

      .coin-listing-amount {
        justify-content: space-between !important;
        padding-left: 80px !important;
      }

      .coin-listing-information {
        grid-area: balance-info;
      }

      .wallet-balance-shares {
        grid-area: balance-shares;
      }

      .wallet-balance-coins {
        grid-area: balance-coins;
      }

      .wallet-balance-returns {
        grid-area: balance-returns;
      }
    }

    @media(max-width: 500px) {
      &.coin-listing-balances {
        grid-template-columns: 1fr;
        grid-template-rows: 60px 100px 100px 40px;
      }

      grid-template-areas: "balance-info" "balance-coins" "balance-shares" "balance-returns";

      .coin-listing-amount {
        padding-left: 0px !important;
        display: grid !important;
        grid-gap: 1rem;
        grid-template-columns: 1fr;
        grid-template-rows: 1fr 1fr;
      }

      .wallet-balance-returns {
        justify-content: flex-start !important;
      }
    }
  }

  .withdraw-modal .input-group {
    margin: 0 32px 32px 0;
  }

  .last-transaction {
    &__pagination {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      padding: 16px;
    }

    &__pagination-label {
      margin-right: 16px;
    }
  }

  #wallet-balances {
    .coin-listing-information {
      margin-bottom: 0;
    }
    .link-container {
      text-align: right;
      margin-top: 20px;
    }
    .link {
      margin-left: auto;
      order: 2;
      padding-top: 20px;
      margin: 5px;
    }
  }

  #wallet-deposits-withdrawals {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(30rem, 1fr));
    grid-gap: 2rem;

    .deposit-withdrawal-container {
      header {
        margin-bottom: 32px;
      }
    }

    .deposit-withdrawal-listing {
      padding: 16px;
      margin-bottom: 0;
      border-bottom: 0.5px solid rgba(0, 0, 0, 0.16);

      &__status-success {
        color: $green;
      }

      &__status-failure {
        color: $red;
      }

      &__status-default {
        color: $text-secondary;
      }

      &__row {
        display: grid;
        grid-template-columns: 1fr 2fr 3fr;
        align-items: center;

        @include media("<phone") {
          grid-template-columns: 80px 70px 150px;
        }
      }

      &__amount {
        display: flex;
        align-items: center;
        justify-content: flex-end;
      }

      &__status {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-left: 32px;
      }

      .deposit-withdrawal-collapsible {
        max-height: 0;
        overflow: hidden;
        transition: 0.2s;
      }

      .deposit-withdrawal-collapsible.collapsible-show {
        max-height: 300px;
        padding: 16px 0;
      }

      .deposit-withdrawal-collapsible-content {
        display: grid;
        grid-template-columns: 1fr 5fr;
        align-items: center;
        padding: 8px 0;

        &__label {
          line-height: 24px;
          text-align: right;
        }

        &__value {
          line-height: 24px;
          padding-left: 32px;

          .btn-link {
            display: inline-block;
            margin-right: 8px;
          }
        }
      }

    }

    .deposit-withdrawal-pagination {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      padding: 16px;
      text-align: right;

      &__label {
        flex: 1;
      }

      &__button-container {
        width: 100px;
      }

      &__button {
        display: inline-block;
        height: 32px;
        width: 32px;
        color: $electricberry;
        border-radius: 6px;
        border: 1px solid #efefef;
        margin: 4px;
        text-align: center;
        box-shadow: $shadow-elevation1;
        cursor: pointer;
        fill: $electricberry;
      }

      &__button.disabled {
        color: #efefef;
        cursor: auto;
        fill: #efefef;
      }
    }
  }

  .shares-queue {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    align-items: center;
  }

  .shares-queue-status {
    padding-left: 8px;
    padding-right: 8px;

    &-sell {
      color: $red;
    }

    &-purchase {
      color: $green;
    }
  }

  .shares-queue-action {
    text-align: right;
  }
}

.promotions {

  &-listing {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    padding: 20px 14px;
    margin: auto;
    @media(max-width: 500px) {
      &.promotions-listing {
        grid-template-columns: 1fr;
        grid-template-rows: 60px 100px 100px;
      }
    }

    &-title {
      display: flex;
      align-items: center;
      @media(max-width: 500px) {
        &.promotions-listing-title {
          margin: auto;
        }
      }

      .coin-listing-event {
        margin-left: 20px;
        font-size: 32px;
      }
    }

    &-value {
      display: flex;
      align-items: center;
      @media(max-width: 500px) {
        &.promotions-listing-value {
          margin: auto;
        }
      }
      .zero-value {
        color: rgba(0, 0, 0, 0.6);;
      }
      .bar {
        position: relative;
        margin: 0 10px;
        width: 100px;
        &-empty {
          position: absolute;
          height: 4px;
          background-color: rgba(0, 0, 0, 0.16);
          border-radius: 6px;
          width: 100%;
        }
        &-full {
          background-color: #6000FF;
          position: absolute;
          height: 4px;
          border-radius: 6px;
        }
      }

      .full-value {
        color: #6400FF;
      }
      .dayLeft {
        margin-left: 24px;
      }
    }

    &-options {
      @media(max-width: 500px) {
        &.promotions-listing-options {
          margin-left: 5%;
        }
      }
    }

  }
  &-text {
    margin-top: 10px;
  }
}

.deposit-withdrawal-collapsible-content__value {
  word-break: break-all;
}

.filter-buttons .select, .filter-buttons .label {
  background-color: #f4f3f7 !important;
  margin-left: 10px;
}

.filter-buttons .select .select__text {
  background-color: #ffffff !important;
}

.filter-buttons .select .select__options {
  background-color: #ffffff !important;
  top: 65px !important;
}

.filter-buttons .select {
  top: 15px;
  min-width: 300px;
}

.filter-buttons .select .label {
  top: 5px;
}

.trading-preference-horizontal-wrapper {
  margin-top: 24px;

  .auto-compound,
  .auto-buy-slices {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    justify-content: flex-start;
    margin-bottom: 2 * $ui-space-base;

    .settings-wrapper {
      display: flex;
    }

    .settings-header {
      margin-bottom: 2 * $ui-space-base;
    }

    .label-and-setting {
      display: flex;
      align-items: center;
      margin-right: 4 * $ui-space-base;
      
      > .select {
        margin-left: 2 * $ui-space-base;
        @include media("<desktop") {
          margin-top: $ui-space-base;
        }

      }

      & .select {
        margin-bottom: 0;

        @include media("<desktop") {
          width: 100%; 
        }
      }
    
      & .select .select__text {
        padding: 3px 40px 3px 12px;
        height: inherit;
      }
    
      & .select .select__caret {
        width: 32px;
        height: 32px;
        float: none;
        top: 0;
        right: 0;
        position: absolute;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    
      .select .select__options {
        top: 32px !important;
      }
    
      .select .select__option {
        padding: 3px 12px;
        height: 32px;
      }

      @include media("<desktop") {
        margin-bottom: 16px;
        flex-direction: column;
        width: 100%;
        margin-right: 0px;

        .select {
          margin-left: 0px;
        }

        > span {
          width: 100%;
        }
      }
    }

    > div {
      flex-grow: 0;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      flex-wrap: wrap;
      width: 100%;
      margin: 8px 0;

      > span {
        margin-bottom: 8px;
      }
    }
  }

  @include media(">desktop") {
    .auto-compound,
    .auto-buy-slices {
      > div {

        > span {
          margin: 0 16px 0 0;
        }
      }
    }
    .select {
      width: auto;
    }
  }

  @include media("<desktop") {
    .auto-compound {
      margin-bottom: 2 * $ui-space-base;
    }
  }
}

@include media(">tablet") {
  .withdrawal-status-page {
    display: flex;
    justify-content: center;
  }

  .withdrawal-status {
    width: 66.67%;
  }
}

// <<< NEW BALANCE PAGE LAYOUT >>>

.wallet-header {
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.32);
  border-radius: $ui-border-radius $ui-border-radius 0px 0px;

  span {
    text-align: end;
    font-weight: $type-weight-medium;
    font-size: 1.6rem;
  }

  @include media("<desktop") {
    display: none !important;
  }

}

.wallet-coin-item {
  background-color: #fff;
  border-left: 1px solid rgba(0, 0, 0, 0.32);
  border-right: 1px solid rgba(0, 0, 0, 0.32);
  border-bottom: 1px solid rgba(0, 0, 0, 0.32);

  .table-num {
    text-align: end;
    color: #6c757d;
    font-weight: $type-weight-medium;
    font-size: 1.6rem;
  }

  .item-column {
    @include media("<desktop") {
      padding-bottom: $ui-space-base;
    }
  }

  &:last-child {
    border-radius: 0px 0px $ui-border-radius $ui-border-radius;
  }

}

.modal-footer {
  background-color: #f4f3f7;
  height: 100px;
  margin-top: 8px;
  display: flex;
  justify-content: flex-end;
  align-items: center;

  & span {
    text-transform: uppercase;
    cursor: pointer;
    margin-right: 25px;
    font-weight: bold;
    color: #6400ff;
  }
}

.balance-container {
  display: grid;
  grid-template-columns: minmax(170px, 2fr) minmax(0, 1.25fr) minmax(0, 1.25fr) minmax(0, 1.25fr) minmax(240px, 270px) minmax(0, 1.25fr) minmax(0, 1.5fr) 275px minmax(0, 0.75fr);
  grid-template-rows: 64px;
  padding-left: $ui-space-base * 3 !important;
  padding-right: $ui-space-base * 3  !important;
  margin: 0px;
  
  @include media("<desktop") {
    display: flex;
    height: auto;
    flex-direction: column;
    padding-top: $ui-space-base * 3 !important;
    padding-bottom: $ui-space-base * 3  !important;
  }
}

.flex-left {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.flex-right {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.coin-name {
  color: $text-default !important;
  font-weight: 500;
}

.coin-name-wrapper {
  @include media("<desktop") {
    padding-bottom: 2* $ui-space-base !important;
  }
}

.space-between-mobile {
  @include media("<desktop") {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: $ui-space-base;
  }
}

.flex-start-mobile {
  @include media("<desktop") {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 0 !important;
  }
}

.mr-16 {
  margin-right: $ui-space-base * 2;
}

.mr-8 {
  margin-right: $ui-space-base;
}

.faded {
  color: $faded !important;
}

.wrapper {
  min-width: 1300px;

  @include media("<desktop") {
    min-width: 0px;
    article:nth-child(2) {
      border-top: 1px solid rgba(0, 0, 0, 0.32);
      border-radius: $ui-border-radius $ui-border-radius 0px 0px;
    }
  }
}

.scrollable {
  overflow: auto;
  box-shadow: 0 4px 16px 0 rgba(0, 0, 0, 0.08), 0 8px 32px 0 rgba(0, 0, 0, 0.08);
}

.coin-name-shorthand {
  font-size: 1.2rem !important;
  margin-left: $ui-space-base * 0.5;
}

.hide-lg {
  @include media(">desktop") {
    display: none;
  }
}

.black {
  color: $black !important;
  font-weight: bold !important;
}

.total-balance {
  @include media("<desktop") {
    padding-bottom: $ui-space-base * 0.5 !important;
  }
}

.available-balance {
  @include media("<desktop") {
    padding-bottom: $ui-space-base * 0.5 !important;
  }
}

.balance-on-hold {
  @include media("<desktop") {
    padding-bottom: $ui-space-base * 2 !important;
  }
}

.deposit-withdraw {
  padding-left: $ui-space-base * 2;
  @include media("<desktop") {
    padding-bottom: $ui-space-base * 3 !important;
    padding-left: 0px;
  }
}

.total-slices {
  @include media("<desktop") {
    padding-bottom: $ui-space-base * 0.5 !important;
  }
}

.usd-value {
  @include media("<desktop") {
    padding-bottom: $ui-space-base * 2 !important;
  }
}

.buy-sell {
  padding-left: $ui-space-base * 3;
  @include media("<desktop") {
    padding-bottom: $ui-space-base * 3 !important;
    padding-left: 0px;
  }
}

.coin-swap-confirmation-modal {
  th, td {
    border-bottom: 0.5px solid rgba(0, 0, 0, 0.16);
  }
}

.bottom-text-and-button {
  justify-content: flex-end;
  height: 49px;

  @include media("<desktop") {
    justify-content: space-between;
  }
}

.buy-modal-buy-button {
  @include media("<desktop") {
    display: flex;
    justify-content: flex-end;
    width: initial;
  }
}

.buy-modal-container, .buy-modal-footer-container{
  @include media("<desktop") {
    padding: 3 * $ui-space-base !important;
  }
}

.buy-modal-coin-selector {
  @include media("<desktop") {
    display: flex;
    justify-content: flex-end;
  }
}

#wallet-balances-buy-modal {
  display: flex;
  //justify-content: space-between;
  align-items: center;
}

.buy-modal-textbox {
  //justify-content: space-between;
  @include media("<tablet") {
    width: 100%;
    margin-right: 2 * $ui-space-base;

    .input-group {
      width: auto;
    }
  }
}

.balances-heading {
  padding-bottom: 0px;
}

.label-and-setting > span > span {
  @include media("<desktop") {
    font-size: 18px;
  }
}

.frozen-balance-container {
  @include media(">=blg") {
    margin-top: 24px;
  }
}