.Toastify__toast-container {
  width: 550px;
  @media only screen and (max-width: 550px) {
    width: 100%;
  }
}

.Toastify__toast-container--top-center {
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
}

.Toastify__close-button.Toastify__close-button--default {
  display: flex;
  align-self: center;
  margin-right: 16px;
  border-radius: 50%;
  background-color: rgba(0, 0, 0, 0.3);
  width: 20px;
  height: 20px;
  justify-content: center;
  line-height: 0;
  padding: 12px;
  font-size: 65%;
}

.cakepool-toaster {
  $icon-size: 30px;
  border-radius: 8px;
  background-color: #ffffff;
  .cakepool-toaster__body {
    @include type-body();
    margin: 0;
    color: $black;
    margin-bottom: 0;
    display: flex;
    padding: 16px;
  }
  .cakepool-toaster__progress {
    background: $cardboard;
  }
  .cakepool-toaster__content-icon {
    width: $icon-size;
    height: $icon-size;
    position: absolute;
    left: 0;
    top: 0;
  }

  .cakepool-toaster__content {
    position: relative;
    display: flex;
    align-items: center;
    height: 100%;
    width: 100%;
    justify-content: space-between;
    &.cakepool-toaster__content--with-icon {
      padding-left: $icon-size + 10px;
    }
  }
  .cakepool-toaster__timer {
    color: $black;
  }
  .cakepool-toaster__small-text {
    font-size: 15px;
  }
  .cakepool-toaster__title-text {
    font-size: 18px;
    font-weight: bold;
  }

  a.toastr-link {
    color: $electricberry !important;
    cursor: pointer;
  }

  .toastr-message {
    font-size: 14px;
  }
}
