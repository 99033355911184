.sprods-page {
  #sprods {
    margin-bottom: 0 !important;
  }

  #sprods h1 {
    margin-bottom: 0 !important;
  }

  #taking-orders,
  #ongoing-trades {
    margin-bottom: 0 !important;
  }

  @media (min-width: 1200px) {
    .selling-batches {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
    }

    .selling-batch {
      margin-bottom: 30px;
    }
  }
}

.cancel-entry {
  max-height: 50px !important;
  display: flex !important;
  align-items: center !important;

  button:disabled {
    color: grey;
  }

  @include media("<tablet") {
    justify-content: center;
    margin-bottom: $ui-space-base;
  }
}

.cancel-button {
  border: none;
  padding: 0;
  background: none;
  color: #F50057;
  outline: 0 !important;
  font-weight: 600;
  text-transform: uppercase;
}

.lapis-hero-card {
  border: $ui-border-width solid $ui-border-color;
  border-radius: $ui-border-radius;
  padding: 32px 24px 0;

  .lapis-hero-content {
    margin-left: 90px;

    @include media("<bxl") {
      margin-left: 24px;
    }

    @include media("<blg") {
      padding: 16px !important;
      margin-left: 0px;
    }
  }
}

.lapis-hero-pic {
  width: 100%;
  height: 100%;
  padding-right: 94px;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;

  @include media("<bxl") {
    padding-right: 32px;
  }

  @include media("<blg") {
    display: none;
  }
}

.lapis-hero-content {
  padding-bottom: 32px;

  h1 {
    font-size: 32px;
    letter-spacing: 0.25px;
    line-height: 40px;
    margin-bottom: 16px;
  }

  p {
    font-size: 16px;
    line-height: 24px;
  }

  .button-container {
    display: flex;
    flex-direction: column;
    align-items: inherit;
  }

  .lending-services-button {
    white-space: break-spaces;
    min-height: 48px;
  }

  .lending-learn-more-button {
    width: 100%;
    margin-top: 16px;
  }

  .lending-learn-more-button-container {
    margin-left: 0;
  }

  @media (min-width: 550px) {
    .button-container {
      flex-direction: row;
      align-items: center;
    }

    .lending-learn-more-button {
      margin-top: 0;
    }

    .lending-learn-more-button-container {
      margin-left: 16px;
    }
  }
}

.lapis-hero {
  background-color: white;
  padding-left: 0px;
  padding-right: 0px;
}
