#confectionery-hero-card {
  position: relative;
  box-shadow: inset 0 -1px 0 0 #222222;
  width: 100%;
  background-color: #5B10FF;
  border-radius: $ui-space-base;
  overflow: hidden;
  height: fit-content;

  .hero-text {
    position: relative;
    z-index: 2;
    margin-left: 48px;
    padding: 40px;
    padding-right: 0px;
    color: white;

    h1 {
      width: 40%;
      margin-bottom: 24px;
      font-size: 34px;
      letter-spacing: 0.25px;
      line-height: 40px;
    }

    .description {
      width: 40%;
      font-size: 16px;
    }

    .btn {
      border: 0px;
      margin-top: 24px;
    }

    @media (max-width: 1140px) {
      margin-left: 8px;
    }

    @include media("<desktop") {
      padding: 48px;
      margin-left: 0px;

      h1,
      .description {
        width: 100%;
      }
    }

    @include media("<=tablet") {
      padding: 48px 24px;
    }
  }

  .hero-text-with-tier {
    position: relative;
    z-index: 2;
    margin-left: 16px;
    padding: 40px;
    padding-right: 0px;
    color: white;

    @include media("<desktop") {
      margin: 0px;
      padding: 48px;
    }

    @include media("<=tablet") {
      margin: 0px;
      padding: 48px 24px;
    }

    .current-tier {
      display: flex;
      align-items: flex-end;
      flex-wrap: wrap;
      flex-direction: row;
      margin-bottom: 24px;
      margin-left: -8px;
      margin-right: -8px;

      >div {
        padding: 8px;
      }

      .tier-title {
        margin-right: 8px;
      }

      h1 {
        margin: 0px;
        font-size: 34px;
        letter-spacing: 0.25px;
        line-height: 40px;
      }
    }
  }

  .frozen-amount {
    font-size: 34px;
    letter-spacing: 0.25px;
    line-height: 40px;
    font-weight: 500;
    margin-top: 8px;
    margin-bottom: 48px;
  }

  .confectionist-hero-picture {
    position: absolute;
    z-index: 1;
    bottom: -1px;
    top: 0px;
    right: -48px;

    img {
      height: 100%;
      width: auto;
      z-index: 1;
    }

    @media (max-width: 1140px) {
      right: -150px;
    }

    @include media("<desktop") {
      display: none;
    }
  }
}

#confectionary-benefits {
  .benefit {
    margin-bottom: 48px;

    .benefit-title {
      font-size: 2rem;
      font-weight: 600;
      letter-spacing: .002em;
      line-height: 2.4rem;
      margin: 0 0 8px;
      display: block;
      margin-top: 3 * $ui-space-base;
    }
  }
}

#tier-table-card {
  width: 100%;
  border: 1px solid #ccc;
  border-radius: 8px;

  @include media("<=tablet") {
    border: 0px;
    background-color: unset;
  }
}

#freezer-table-card {
  width: 100%;
  border: 1px solid #ccc;
  border-radius: 8px;
}

#tier-levels-table {
  display: grid;
  grid-template-columns: minmax(150px, 2fr) repeat(4, minmax(100px, 1fr));
  font-size: 16px;

  .table-col {
    &:first-child {
      .table-cell {
        justify-content: flex-start;
        font-weight: 500 !important;
        text-align: left !important;
        padding-left: 24px;

        &:first-child {
          font-size: 20px;
        }
      }
    }

    &:last-child {
      .table-cell {
        padding-right: 24px;
      }
    }
  }

  .table-cell {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    height: 64px;
    border-top: 1px solid #ccc;

    &.no-border {
      border-top: none;
    }

    @media (max-width: 1000px) {
      height: 80px;
    }

    @media (max-width: 810px) {
      height: 114px;
    }

    &.support-options {
      height: 270px;
      align-items: flex-start;

      >div {
        justify-content: flex-start;
      }

      @media (max-width: 1370px) {
        height: 300px;
      }

      @media (max-width: 1104px) {
        height: 350px;
      }

      @media (max-width: 895px) {
        height: 400px;
      }

      span {
        margin-bottom: 8px;

        &:last-child {
          margin-bottom: 0px;
        }
      }
    }

    &.last-cell {
      height: 100px;
    }

    >div {
      padding: 24px 4px;
      flex-direction: column;
      display: flex;
      align-items: center;
      justify-content: center;

      span {
        font-size: 16px !important;
      }
    }

    &:first-child {
      font-size: 16px;
      font-weight: 500 !important;
      border-top: 0px;
    }

    &.hint {
      color: rgba(0, 0, 0, 0.4);
    }

  }

  &.last-row-hidden {
    .last-cell {
      @include media(">tablet") {
        display: none !important;
      }
    }
  }

  .secondary {
    font-style: italic;
    color: rgba(0, 0, 0, 0.6);
  }
}

.mobile-only {
  @include media(">tablet") {
    display: none !important;
  }
}

.desktop-only {
  @include media("<=tablet") {
    display: none !important;
  }
}

.signup-row {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 24px;
  border-top: 1px solid #ccc;

  @include media("<=tablet") {
    display: none;
  }
}

.signup-row-mobile {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 16px;
  border-top: 1px solid #ccc;

  @include media(">tablet") {
    display: none;
  }
}

#tier-levels-table {
  @include media("<=tablet") {
    display: flex !important;
    flex-direction: column;

    .table-col {
      border: 1px solid #ccc;
      border-radius: 8px;
      background-color: #fff;
      position: relative;
      display: flex;
      flex-direction: column;
      min-width: 0;
      margin-bottom: 24px;

      &:first-child {
        display: none;
      }

      &:last-child {
        .table-cell {
          padding-right: 0px;
        }
      }
    }

    .table-cell {
      height: auto;

      &:first-child {
        font-size: 20px !important;
        border-top: 0px;
      }

      &.support-options {
        height: auto;
      }

      >div {
        padding: 16px;
      }
    }
  }
}


#freezer-table {
  h2 {
    margin: 0px;
  }

  .table-header>.table-cell {
    font-size: 16px !important;
    color: black !important;
    font-weight: 500 !important;
  }

  .table-row {
    border-bottom: 1px solid #ccc;
    display: grid;
    grid-template-columns: 1.5fr 2fr 2fr 2fr 1.5fr;
    padding: 0px 16px;
    grid-template-rows: 64px;
    font-size: 16px;
    text-align: right;

    &:first-child {
      border: none;
    }

    &:last-child {
      border: none;
    }
  }

  .table-cell {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: 8px;
    color: rgba($black, 0.6);
    font-weight: 500;

    span {
      font-size: 16px !important;
    }

    &.secondary {
      color: rgba(0, 0, 0, 0.3);
    }
  }

  @include media("<=tablet") {
    .table-row {
      display: flex;
      height: auto;
      flex-direction: column;
      font-size: 18px;
      padding: 16px 0px;
      text-align: left;
    }

    .table-cell {
      justify-content: space-between;
      padding: 8px 24px;

      span {
        font-size: 18px !important;
      }

      >span:first-child {
        color: $black;
        margin-right: 8px;
      }
    }
  }
}

.freezer-load-more-row {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 64px;

  >span {
    margin: 0;
    color: #5B10FF;
    background-color: #fff;
    text-decoration: none;
    text-transform: uppercase;
    font-size: 17px;
    font-weight: bold;
    letter-spacing: 0.86px;
    cursor: pointer;
  }
}

.promotional-banner {
  padding: 24px;

  @include media("<=tablet") {
    padding: 0;
    margin-bottom: 24px;
  }
}

.freezer-table-header {
  padding: 24px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.light-white-big {
  color: rgba($white, 0.8);
  font-size: 20px;
}

.light-white-normal {
  color: rgba($white, 0.8);
  font-size: 16px;
}

.confectionery-progress {
  align-items: center;

  .confectionery-progress-bar {
    width: 96px;
    height: 12px;
    border-radius: 6px;
    margin-right: 16px;

    &.apprentice-bar {
      div {
        background-color: #5B10FF;
      }
    }

    div {
      background-color: #8068ff;
    }
  }

  >div {
    color: rgba($white, 0.8);
    font-size: 16px;
    padding-top: 8px;
    padding-bottom: 8px;
  }

  @include media(">bsm") {
    :first-child {
      padding-right: 0px;
    }

    :nth-child(2) {
      padding-left: 8px;
    }
  }
}

.confectionery-support {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 24px;

  >div {
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 24px;
  }

  >button {
    margin-bottom: 24px;
  }
}
