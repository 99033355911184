[class^="icon-"] {
  width:                        $ui-icon-width;
  height:                       $ui-icon-width;
  display:                      inline-block;

  svg {
    fill:                       currentColor;
    width:                      $ui-icon-width;
    height:                     $ui-icon-width;
  }
}

a[class^="icon-"] {
  color:                        $electricberry;
}