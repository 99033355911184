$color: #ffffff;
$border-color: #eeeeee;
// $height: 35px;
$height: 50px;
.dropdown {
  position: relative;
  overflow: visible;
  display: inline-block;

  .dropdown__content {
    display: inline-block;
    position: relative;
    cursor: pointer;
  }
  .dropdown__options-container {
    border: 0.5px solid rgba(0, 0, 0, 0.16);
    padding: 8px 0;
    z-index: 1060;
    background-color: #fff;
    border-radius: 8px;
    display: block;
    position: absolute;
  }
  .dropdown__option,
  .dropdown__heading {
    padding: 8px 16px;
    text-decoration: none;
    display: block;
    text-align: left;
  }
  .dropdown__option {
    cursor: pointer;
    color: #000;
    white-space: nowrap;
    
    &:hover {
      background-color: #eee;
    }
  }
  .dropdown__heading {
    background-color: #ddd;
    font-weight: bold;
  }
}
