.twitter-icon {
  path:nth-child(2) {
    fill: #1C9DEC;
  }
}

.youtube-icon {
  path:nth-child(2) {
    fill: #FF0000;
  }
}

.linkedin-icon {
  path:nth-child(2) {
    fill: #0E76A8;
  }
}

.facebook-icon {
  g {
    path:nth-child(2) {
      fill: #3B5998;
    }
  }
}

.reddit-icon {
  path:nth-child(2) {
    fill: #ff5700;
  }
}