$font-prefix: "../fonts";
@import url("https://fonts.googleapis.com/css?family=IBM+Plex+Sans:400,500,600");

@font-face {
  font-family: 'DiaBlack';
  src: url(../../../assets/fonts/Dia-Black.woff) format('woff');
}

html {
  font-size: 62.5%;  /* =10px */
}

body {
  @include type-body();
  color: $text-default;
}

.h0 {
  @include type-h0();

  @include media("<tablet") {
    @include type-h1();

    text-align: left;
    margin: 0;
  }
}

h1,
.h1 {
  @include type-h1();

  @include media("<tablet") {
    @include type-h2();

    text-align: left;
  }
}

h2,
.h2 {
  @include type-h2();
}

.title {
  @include type-h2();
  margin: 0;
}

.main-logo {
  @include type-logo();
}

h3,
.h3 {
  @include type-h3();
}

.lead {
  @include type-lead();
}

p,
ul,
ol {
  @include type-body();
}

.no-margin {
  margin: 0;
}

.btn {
  @include type-btn();
}

.monospace {
  font-family: $type-mono-family;
}

.align-left {
  text-align: left;
}

.align-center {
  text-align: center;
}

.align-right {
  text-align: right;
}

.text-secondary {
  color: $text-secondary;
}

.text-green {
  color: $green;
}

.text-orange {
  color: $orange;
}

.coin-symbol {
  color: $text-secondary;

  @include type-symbol();

  vertical-align: 0.5rem;
}

h1 .coin-symbol {
  vertical-align: 1.5rem;
}

a {
  color: $link-default;
  text-decoration: none;

  &:hover {
    color: $link-default;
  }
  
}

hr {
  height: 1px;
  margin: 0;
  padding: 0;
  font-size: 0;
  background-color: $ui-border-color;
  color: transparent;
  border: 0;
}
