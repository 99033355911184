
.bonus-explanation-popover {
  display: flex;
  flex-direction: column;
  width: 300px;

  @include media("<tablet") {
    width: 208px;
  }
}

.bonus-explanation-section {
  display: flex;
  flex-direction: column;
  padding: 4px 16px;
  width: 100%;
  border-bottom: $ui-border-width solid $ui-border-color;

  &:last-child {
    border-bottom: 0px;
  }
}

.bonus-explanation-row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 16px !important;
  font-weight: 500;

  > div {
    padding: 4px;

    &:last-child {
      text-align: end;
    }
  }

  &.small-grey {
    color: $grey;
    font-size: 14px !important;
  }
}
