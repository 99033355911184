@-webkit-keyframes fadeOut {
  0% {
    opacity: 1;
  }
  99% {
    opacity: 0.01;
    width: 100%;
    height: 100%;
  }
  100% {
    opacity: 0;
    width: 0;
    height: 0;
  }
}
@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  99% {
    opacity: 0.01;
    width: 100%;
    height: 100%;
  }
  100% {
    opacity: 0;
    width: 0;
    height: 0;
  }
}

.fade-out {
  display: block;
  -webkit-animation: fadeOut 1s;
  animation: fadeOut 1s;
  animation-fill-mode: forwards;
}

.bold {
  font-weight: bold;
}

.floating-top-left {
  position: absolute;
  top: 0;
  left: 0;
}

.full-size {
  width: 100%;
  height: 100%;
  overflow: auto;
}

.full-window {
  width: 100vw;
  height: 100vh;
  overflow: auto;
}

.full-width {
  width: 100%;
}

.full-height {
  height: 100%;
}

.page-container {
  width: 100%;
  height: 100%;
}

.flex-vertical-center {
  display: flex;
  align-items: center;
}

.flex-horizontal-center {
  display: flex;
  justify-content: center;
}

.flex-center {
  @extend .flex-horizontal-center;
  @extend .flex-vertical-center;
}

.pagination-justify-center {
  .pagination {
    justify-content: center;
  }
}

.fancy-scrollbar {
  &::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: #f5f5f5;
    border-radius: 10px;
  }

  &::-webkit-scrollbar {
    width: 10px;
    background-color: #f5f5f5;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-image: linear-gradient(326deg, #a4508b 0%, #5f0a87 74%);
  }
}

.align-center {
  text-align: center;
}

.align-right {
  text-align: right;
}

.horizontal-margin {
  margin: 0 5px;
}

.text-bottom {
  vertical-align: text-bottom;
}

.vertical-padding {
  padding: 5px 0;
}

.clickable-hover {
  cursor: pointer;
  display: inline-block;
  &__full-width {
    width: 100%;
  }
}

.detail-title {
  font-weight: bold;
}

.detail-row {
  border-bottom: 1px solid #eee;
  margin-left: 0;
  margin-right: 0;
  padding-top: 10px;
  padding-bottom: 10px;
}

.inline-block {
  display: inline-block;
}

.input-group label {
  width: 100%;
}

.link {
  color: $electricberry;
  cursor: pointer;

  &.btn {
    color: $white;
    display: inline-block;
  }
}

.hr-margin {
  margin-top: 32px;
  margin-bottom: 32px;
}

.margin-top-20 {
  margin-top: 20px;
}

.margin-top-24 {
  margin-top: 24px;
}

.margin-top-32 {
  margin-top: 32px;
}

.margin-bottom-40 {
  margin-bottom: 40px;
}

.margin-bottom-32 {
  margin-bottom: 32px !important;
}

.margin-bottom-24 {
  margin-bottom: 24px;
}

.margin-left-20 {
  margin-left: 20px;
}

.margin-right-20 {
  margin-right: 20px!important;
}

.white-background {
  background: #fff;
}

.pt-10 {
  padding-top: 10px;
}

.pb-40 {
  padding-bottom: 40px;
}

.ptb-50 {
  padding-top: 50px;
  padding-bottom: 50px;
}

.ptb-210 {
  padding-top: 210px;
  padding-bottom: 210px;
}

.hidden {
  display: none;
}

.fontweight-500 {
  font-weight: 500
}

.flex-end {
  justify-content: flex-end;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.word-wrap {
  word-wrap: break-word;
}

.no-border-bottom {
  border-bottom: none !important;
}

.no-background {
  background-image: none;
}

.help-text {
  color: $red;
}

.download-app-store {
  height: 50px;
  margin: 5px;
}

.fright {
  float: right;
}

.fleft {
  float: left;
}

.nowrap {
  white-space: nowrap;
}

.pt0 {
  padding-top: 0;
}

.coin-swap-message {
 h3 {
   font-weight: 300;
   margin-bottom: 3.6rem;
   text-align: justify;
 }
}

button:disabled {
  cursor: not-allowed;
  pointer-events: none;
}

.react-datepicker, .react-datepicker__input-container {
  font-size: .8em;
}

.react-datepicker__input-container input[readonly] {
  background-color: WhiteSmoke; 
}

.cursive {
  font-style: italic;
}

.light-bold {
  font-weight: 500;
}

.mb-16 {
  margin-bottom: 16px !important;
}

.mb-0 {
  margin-bottom: 0 !important;
}

.disabled-look {
  opacity: 0.5;
}

.text-align-start {
  text-align: start;
}

.no-wrap-mobile {
  @include media("<tablet") {
    flex-wrap: nowrap;
  }
}

.red-btn {
  background-color: $red;
}

.padding-0 {
  padding: 0
}

.no-border {
  border: '0 none'
}

.border-radius-small {
  border-radius: 8px;
}

.word-wrap {
  word-break: break-word;
}

.flex-wrap {
  flex-wrap: wrap;
}

.grey {
  color: $grey;
}

.mb-8 {
  margin-bottom: 8px;
}

.mb-16 {
  margin-bottom: 16px;
}

.mb-24 {
  margin-bottom: 24px;
}

.mt-24 {
  margin-top: 24px;
}

.mb-32 {
  margin-bottom: 24px;
}
