.home-page {
  .btn-flat {
    padding: 12px 24px;
    cursor: pointer;
  }

  @include media("<desktop") {
    .btn-signup {
      margin: 0 !important;
    }
  }

  .home__collapse-icon-container {
    cursor: pointer;
    width: 9px;
    height: 9px;
    display: inline-block;
    margin-left: 10px;
  }

  .home__share-container {
    display: inline-block;
    width: 100%;
  }

  .home__coin-icon-container {
    width: 32px;
    height: 32px;
  }

  .coin-info-container {
    position: relative;
    width: 32px; // just in case

    &__icon {
      cursor: pointer;
      fill: $text-secondary;
      margin: 0px 8px;
    }

    &__popover {
      position: absolute;
      min-width: 350px;
      left: -100%;
      margin-top: 16px;
      display: none;
      padding: 16px;

      background-color: #fff;
      border: 0.5px solid rgba(0, 0, 0, 0.16);
      border-radius: 8px;
      box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.16);

      z-index: 99;
    }

    @media only screen and (max-width: 620px) {
      &__popover {
        left: unset;
        transform: translateX(-270px);
        min-width: 320px;
        max-width: 320px;
      }
    }

    &:hover .coin-info-container__popover {
      display: block;
    }
  }

  .share-info-content {
    display: flex;
    @include type-activity();

    &__label {
      line-height: 24px;
      flex: 1;
    }

    &__value {
      line-height: 24px;
      flex: 1;
    }
  }

  .hero {
    height: 480px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: $white;
    background-size: 767px 176px;
    background-position: center bottom;
    background-repeat: no-repeat;

    background-image: url("../../../assets/img/hero/hero-phone.png");

    @include media(">=tablet") {
      background-image: url("../../../assets/img/hero/hero-tablet.png");
      background-size: 1023px 192px;
    }

    @include media(">=desktop") {
      background-image: url("../../../assets/img/hero/hero-desktop.png");
      background-size: 1439px 192px;
    }

    @include media(">=hd") {
      background-image: url("../../../assets/img/hero/hero-hd.png");
      background-size: 1919px 192px;
    }

    @include media(">=uhd") {
      background-image: url("../../../assets/img/hero/hero-uhd.png");
      background-size: 2559px 208px;
    }
  }

  .hero-content {
    margin-bottom: 192px;
    padding-left: 24px;
    padding-right: 24px;
    text-align: center;

    h1 {
      text-align: center;
    }

    @include media(">=uhd") {
      margin-bottom: 208px;
    }
  }

  .hero-cta {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
  }

  .coin-listing-container {
    display: grid;
    grid-gap: 1rem;
  }
  .coin-listing {
    padding: 16px 32px !important;
    overflow: inherit;
    display: grid;
    grid-template-columns: 1fr max-content 1fr;

    .coin-listing-information {
      display: grid;
      grid-template-columns: 80px 150px 50px;
    }

    .coin-listing-stats .coin-listing-stat {
      margin: 0;
    }

    .coin-listing-actions {
      margin: 0;
      padding: 16px 0;
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-end;

      p {
        font-size: $type-activity-size;
        color: $text-secondary;
        padding-left: 32px;
        text-align: right;
        margin-top: 1.2rem;
        margin-bottom: 0;
      }
    }

    @include media("<=desktop") {
      grid-template-columns: 1fr 1fr;
      grid-template-rows: 1fr 1fr;
      grid-template-areas:
        "coin-info coin-action"
        "coin-stat coin-stat";

      .coin-listing-information {
        grid-area: coin-info;
        min-height: 48px;
      }
      .coin-listing-stats {
        grid-area: coin-stat;
        .coin-listing-stat {
          margin-right: 32px;
        }
      }
      .coin-listing-actions {
        grid-area: coin-action;
        justify-content: flex-start;

        .btn {
          margin-left: 0;
        }

        p {
          text-align: left;
          padding-left: 0;
        }
      }
    }

    @media (max-width: 500px) {
      grid-template-columns: 1fr;
      grid-template-rows: 48px 108px 128px;
      grid-template-areas:
        "coin-info"
        "coin-stat"
        "coin-action";
    }
  }

  .no-background {
    background-image: none;
  }

  .home__section-content {
    display: grid;
    grid-gap: 1rem;
  }

  // HERO HERE

  .home__page-title {
    @include type-h1();
    display: inline-block;
  }

  .home__section-title {
    display: inline-block;
  }

  .home__section-selector-title {
    font-weight: 500;
    line-height: 40px;
    padding-right: 16px;
  }

  .home__feature-element {
    margin-bottom: 32px;
  }

  .home__feature-element-image {
    height: 64px;
    width: 64px;
    display: block;
    margin-bottom: 24px;
    @include card-appearance();
    box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.04);
    border-radius: $ui-border-radius;
    display: flex;
    align-items: center;
    justify-content: center;

    svg {
      transform: scale(1.5);
      fill: $electricberry;
    }
  }

  .home__feature-element-title {
    @include type-h3();
    margin-bottom: 8px;
    display: block;
  }

  .home__feature-element-description {
    display: block;
  }

  .base-price-title {
    padding-left: 164px;
  }
}

.home-hero {
  @include media("<blg") {
    margin-top: 0px;
  }
}

.hero-left-side {
  width: 60%;
  margin-left: 64px;

  h1 {
    font-size: 48px;
    line-height: 56px;
  }

  @include media("<blg") {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin-left: 0px;
    margin-bottom: 32px;
    margin-top: 0px;
    text-align: center;
    padding: 0px 48px;

    h1 {
      font-size: 36px;
      line-height: 40px;
      text-align: center;
    }
  }

  @include media("<bmd") {
    padding: 0px 16px;
  }
}

.apy-card-section {
  margin-top: 120px;
  margin-bottom: 32px;

  @include media("<blg") {
    margin-top: 0px;
  }
}

.apy-card {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  border: $ui-border-width solid $ui-border-color;
  border-radius: $ui-border-radius;
  background-color: #ffffff;
}

.apy-card-coin {
  display: flex;
  position: relative;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 16px;
  flex: 0 0 16.666666666%;
  max-width: 16.666666666%;
  border-right: $ui-border-width solid $ui-border-color;

  &:last-child() {
    border-right: 0px;
  }

  .apy {
    font-size: 24px;
    line-height: 32px;
  }

  .apy-coin {
    margin-bottom: 8px;
    font-size: 17px;
    font-weight: 500;
    line-height: 24px;
  }

  @include media("<bxl") {
    flex: 0 0 33.333333333%;
    max-width: 33.333333333%;

    &:nth-child(n+4) {
      border-top: $ui-border-width solid $ui-border-color;
    }

    &:nth-child(3) {
      border-right: 0px;
    }
  }

  @include media("<bmd") {
    flex: 0 0 50%;
    max-width: 50%;

    &:nth-child(n+3) {
      border-top: $ui-border-width solid $ui-border-color;
    }

    &:nth-child(3) {
      border-right: $ui-border-width solid $ui-border-color;
    }

    &:nth-child(2n) {
      border-right: 0px;
    }
  }
}

.apy-coin-name {
  position: relative;
}

.apy-light-font {
  position: absolute;
  top: -5px;
  margin-left: 4px;
  color: $text-secondary;
  letter-spacing: 0.25px;
  font-size: 14px;

  @media (min-width: 768px) {
    font-size: auto;
  }
}

.apy-card-coin-icon {
  position: absolute;
  top: 16px;
  left: 16px;
  margin-left: -4px;
  margin-top: -4px;

  @include media("<bmd") {
    top: 16px;
    left: 16px;
  }
}

.coin-apy {
  position: relative;
}

.apy-percent-sign {
  position: absolute;
  top: -5px;
  margin-left: 4px;
  font-size: 16px;

  @media (min-width: 768px) {
    font-size: auto;
  }
}

.sign-up-column {
  display: flex;
  justify-content: flex-end;
}

.home-sign-up-section {
  margin-right: 64px;

  .input-group, .cakepool-checkbox {
    margin-bottom: 24px;
  }

  .signup-btn{
    width: 100%;
  }

  @include media("<blg") {
    width: 100%;
    margin-right: 0;
    margin-bottom: 24px;
  }

  &-center {
    @extend .home-sign-up-section;
    margin: 0 auto;
  }
}

.home-explainer-video-button {
  display: flex;
  align-items: center;
  padding: 8px;
  flex-basis: 100%;

  @include media("<blg") {
    justify-content: center;
    padding-bottom: 24px;
  }

  img {
    width: 160px;
    border-radius: 6px;
  }

  &-desc {
    margin-left: 2.4rem;

    h4 {
      font-size: 1.8rem;
    }
  }
}

.home-buttons {
  display: flex;
  flex-wrap: wrap;
  margin-left: -8px;
  margin-right: -8px;

  @include media("<blg") {
    align-items: center;
    justify-content: center;
  }
}
