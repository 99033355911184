// Resets

body {
  margin:                       0;
  padding-bottom:               $ui-space-base * 6;
  background-color:             $white;
}


// Main

body > header,
body > footer,
main > section {
  padding-left:                 $ui-space-base * 3;
  padding-right:                $ui-space-base * 3;
  box-sizing:                   border-box;

  @include media(">desktop") {
    padding-left:               $ui-space-base * 4;
    padding-right:              $ui-space-base * 4;
  }

  &.no-padding {
    padding-left:               0;
    padding-right:              0;
  }
}

.main-logo {
  height:                       $ui-space-base * 4;
  width:                        144px;
  line-height:                  $ui-space-base * 4;
  margin-right:                 $ui-space-base * 2;
  background-image:             url($svg-logo);
  background-position:          0 center;
  background-repeat:            no-repeat;
  position:                     absolute;
  top:                          $ui-space-base * 2;
  left:                         $ui-space-base * 3;

  @include hide-text();

  @include media(">tablet") {
    position:                   absolute;
    top:                        $ui-space-base * 3;
  }

  @include media(">desktop") {
    left:                       $ui-space-base * 4;
  }
}

body > header {
  width:                        100%;
  height:                       $main-header-height;
  padding:                      0 $ui-space-base;
  display:                      flex;
  align-items:                  center;
  position:                     fixed;
  justify-content:              space-between;
  bottom:                       0;
  z-index:                      $zindex-fixed + 10;
  overflow:                     auto;

  @include media("<tablet") {
    border-top:                 $ui-border-width solid $ui-border-color;
  }

  @include media(">tablet") {
    position:                   static;
    padding:                    0 $ui-space-base * 2 0 $ui-space-base * 25;
    height:                     $ui-space-base * 10;
  }

  .user-menu {
    display:                    flex;
    align-items:                center;
  }

  a, span {
    padding:                    $ui-space-base;

    @include media(">tablet") {
      padding:                  $ui-space-base * 2;
    }
  }

  .btn {
    margin:                     $ui-space-base * 2;
    min-width:                  $ui-space-base * 10;
  }

  .user-picture {
    padding:                    $ui-space-base * 0.5;
    margin:                     $ui-space-base * 0.5;

    @include media(">desktop") {
      margin:                   $ui-space-base * 1.5;
    }

    img {
      width:                    $ui-space-base * 5;
      height:                   $ui-space-base * 5;
      border-radius:            50%;
      display:                  block;
    }
  }
}


// Container

body > main {
  margin-top:                   $ui-space-base * 8;

  @include media(">tablet") {
    margin-top:                 0;
  }

  section,
  hr {
    margin-bottom:              $ui-space-base * 4;
  }

  hr {
    margin-top:                 $ui-space-base * 4;
  }

  > article > header {
    border-bottom:              $ui-border-width solid $ui-border-color;
  }
  > article > section,
  > article > footer {
    margin-bottom:              0;
    background-color:           $white;
    border-bottom:              $ui-border-width solid $ui-border-color;
  }
}

.floating {
  max-width:                    400px;
  margin:                       0 auto;

  > header,
  > section {
    border:                     0;
  }

  > section {
    @include card-appearance;

    @include media(">phone") {
      border:                   0;
      border-radius:            $ui-border-radius;
      overflow:                 hidden;
      box-shadow:               $shadow-elevation3;
    }
  }

  .input-group:last-child {
    margin-bottom:              0;
  }

  .btn {
    width:                      100%;
  }
}

.container {
  max-width:                    map-get($breakpoints, hd);
  padding-left:                 $ui-space-base * 3;
  padding-right:                $ui-space-base * 3;
  padding-top:                  $ui-space-base * 3;
  padding-bottom:               $ui-space-base * 3;
  margin-left:                  auto;
  margin-right:                 auto;

  @include media(">desktop") {
    padding-left:               $ui-space-base * 4;
    padding-right:              $ui-space-base * 4;
    padding-top:                $ui-space-base * 4;
    padding-bottom:             $ui-space-base * 4;
  }

  &.container-fluid {
    max-width:                  100%;
  }

  &.container-narrow {
    max-width:                  map-get($breakpoints, tablet);
  }

  &.container-flex {
    display:                    flex;
    justify-content:            space-between;
    align-items:                flex-start;
    flex-wrap:                  wrap;
  }

  &.flex-align-items-center {
    align-items:                center;
  }

  &.pb0 {
    @include media(">tablet") {
      padding-bottom: 0;
    }
  }
}


// Flex order/grow

@for $i from 0 through 5 {
  .flex-order-#{$i} {
    order: $i;
  }
}

@for $i from 0 through 5 {
  .flex-grow-#{$i} {
    flex-grow: $i;
  }
}

// Widths

// @for $i from 1 through 10 {
//   .w-#{$i*10} {
//     width: $i*10px;
//   }
// }


// Visibility

[class^="hide-"] {
  display: initial;
}

.hide-phone {
  @include media(">phone") {
    display: none;
  }
}

.hide-tablet {
  @include media(">tablet") {
    display: none;
  }
}

.hide-desktop {
  @include media(">desktop") {
    display: none;
  }
}

.hide-hd {
  @include media(">hd") {
    display: none;
  }
}


article {
  margin-bottom:                $ui-space-base * 4;

  header {
    h1, h2, h3 {
      margin:                     0;
    }
  }
}


// Hero

.hero {
  height:                       $ui-space-base * 80;
  margin-bottom:                0;
  padding:                      0;

  @include media(">tablet") {
    height:                     $ui-space-base * 60;
  }

  .container-flex {
    background-repeat: no-repeat;
    background-size: 455px 293px;
    background-position: 50% calc(100% - 48px);
    height: 100%;

    @include media(">tablet") {
      background-position: calc(100% - 48px) 100%;
    }

    @include media(">desktop", "landscape") {
      background-position: 85% 80%;
    }

    @include media(">hd") {
      background-size: 606px 390px;
    }
  }

}


// Footer

body > footer {
  display:                      flex;
  align-items:                  center;
  align-content:                center;
  justify-content:              center;

  @include media("<tablet") {
    padding-bottom:             $ui-space-base * 12;
  }
}


// Coin layout

.page-coin .coin {
  border-top:                   $ui-border-width solid $ui-border-color;
}

.coin-summary {
  position:                     relative;
  background-color:             $white;
  border-bottom:                $ui-border-width solid $ui-border-color;
  background:                   linear-gradient(to right,
                                  rgba($electricberry, 0.0) 0%,
                                  rgba($electricberry, 0.1) 100%
                                ),
                                $white;

  .container-flex {
    justify-content:            space-between;
    align-items:                center;
    flex-wrap:                  wrap;
    position:                   relative;
    z-index:                    1;
  }

  h1 {
    flex-grow:                  3;
    min-width:                  50%;
    margin:                     0;
    line-height:                4.8rem;
  }

  .coin-progress {
    flex-grow:                  2;

    @include media("<desktop") {
      margin-top:               $ui-space-base ;
    }

    @include media(">desktop") {
      text-align:               right;
    }

    .coin-progress-needed {
      display:                  block;

      @include type-h2();

      margin:                   0;
    }
  }

  .coin-progress-bar {
    position:                   absolute;
    top:                        0;
    left:                       0;
    z-index:                    0;
    width:                      100%;
    height:                     100%;
  }
}

.coin-content {
  background-color:             $white;
  border-bottom:                $ui-border-width solid $ui-border-color;
  margin-bottom:                0;

  .container-flex {
    justify-content:            space-between;
    align-items:                flex-start;
    flex-wrap:                  wrap;
  }

  .coin-description {
    display: flex;
    padding: 8px 0;
  }

  .coin-buy-summary {
    margin-top: 2.4rem;
    margin-bottom: 0;
    display: flex;
  }

  .coin-information {
    flex-grow:                  2;
    flex-wrap:                  wrap;
    display:                    flex;
    justify-content:            space-between;

    @include media(">desktop") {
      flex-grow:                3;
    }
  }

  .coin-stat {
    margin-bottom:              $ui-space-base * 3;
    text-align:                 left;
    width:                      calc(50% - #{$ui-space-base});

    @include media(">desktop") {
      width:                    calc(33% - #{$ui-space-base});
    }

    .coin-stat-number {
      display:                  block;

      @include type-h1();

      font-family:              $type-mono-family;
    }
  }

  .coin-actions {
    flex-grow:                  2;

    .btn {
      min-width:                $ui-space-base * 24;
      margin-top:               $ui-space-base * 2;
      margin-bottom:            $ui-space-base * 2;
    }
  }
}

.coin-activity {
  background-color:             $white;
  color:                        $text-secondary;
  width:                        100%;

  @include type-activity();

  .container-flex {
    flex-wrap:                  wrap;
  }

  .coin-activity-item {
    width:                      100%;
    display:                    flex;
    justify-content:            space-between;
  }

  .coin-activity-highlight {
    color:                      $text-default;
  }
}


// Wallet

.coin-qr-code {
  width:                        $ui-space-base * 20;
  height:                       $ui-space-base * 20;

  img {
    width:                      100%;
    height:                     100%;
    display:                    block;
  }
}

.coin-address {
  max-width:                    100%;
  overflow:                     auto;
  padding:                      $ui-space-base  $ui-space-base * 1.5;
  background-color:             $faded;
  border-radius:                $ui-border-radius;

  @include media("<desktop") {
    margin-top:                 $ui-space-base * 3;
  }
}

#deposit-modal .coin-content .container-flex {
  align-items:                  center;
  justify-content:              space-around;
}

#withdraw-modal footer .container-flex,
#pledge-modal footer .container-flex {
  justify-content:              space-between;
}


// Images

.container {
  img {
    @include media("<tablet") {
      width:                    100%;
      height:                   auto;
    }
  }
}


// Side Navigation

.side-nav {
  list-style:                   none;
  padding-left:                 0;

  a {
    color:                      $text-secondary;
    line-height:                3.2rem;
  }
}
