.staking-shares-wrapper {
  @media (min-width: 1200px) {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  @include media("<tablet") {
    margin-top: 100px;
  }
}

.staking-shares-product {
  width: 31.5%;
  border: $ui-border-width solid $ui-border-color;
  border-radius: $ui-border-radius;
  background-color: $white;
  padding: 25px;
  margin-bottom: 25px;

  @media (max-width: 1200px) {
    margin-bottom: 25px;
    width: 100%;
  }

  .lapis-body {
    .body-row {
      display: flex;

      > div {
        flex-grow: 1;
      }

      .h2,
      h3 {
        margin-bottom: 0;
      }

      span {
        display: block;
      }

      .btc-icon {
        transform: scale(1.5);
        flex-grow: 0;
      }

      .staking-name {
        display: flex;

        h3 {
          margin-right: 8px;
        }
      }
    }

    .first-row {
      margin-bottom: 16px;
    }

    .second-row {
      margin-bottom: 16px;
    }
  }

  .lapis-footer {
    display: flex;
    align-items: center;

    .btn {
      margin-right: 16px;
    }

    .min-investable-amount {
      flex-basis: 100%;
      text-align: right;
      color: $text-secondary;

      .amount {
        margin-right: 0px;
        font-size: 1.4rem;
        line-height: 2.0rem;
      }

      .minimum {
        font-size: 1.2rem;
        line-height: 1.6rem;
      }
    }
  }

  .share-empty-notice {
    margin-top: 25px;
    margin-bottom: 0;
  }
}

.share-info-popover {
  padding: 10px;

  * {
    margin-bottom: 10px;
  }

  :last-child {
    margin-bottom: 0px;
  }
}
