// Typography

@use "sass:math";

@mixin type-h0() {
  font-family:                  $type-base-family;
  font-size:                    $type-h0-size;
  font-weight:                  $type-h0-weight;
  letter-spacing:               $type-h0-letter-spacing;
  line-height:                  $type-h0-line-height;
  margin:                       $type-h0-margin;
}

@mixin type-h1() {
  font-family:                  $type-base-family;
  font-size:                    $type-h1-size;
  font-weight:                  $type-h1-weight;
  letter-spacing:               $type-h1-letter-spacing;
  line-height:                  $type-h1-line-height;
  margin:                       $type-h1-margin;
}

@mixin type-logo() {
  font-family:                  $type-heading-family;
  font-size:                    $type-logo-size;
  font-weight:                  $type-logo-weight;
  letter-spacing:               $type-logo-letter-spacing;
  line-height:                  $type-logo-line-height;
  margin:                       0;
}

@mixin type-h2() {
  font-family:                  $type-heading-family;
  font-size:                    $type-h2-size;
  font-weight:                  $type-h2-weight;
  letter-spacing:               $type-h2-letter-spacing;
  line-height:                  $type-h2-line-height;
  margin:                       $type-h2-margin;
}

@mixin type-h3() {
  font-family:                  $type-heading-family;
  font-size:                    $type-h3-size;
  font-weight:                  $type-h3-weight;
  letter-spacing:               $type-h3-letter-spacing;
  line-height:                  $type-h3-line-height;
  margin:                       $type-h3-margin;
}

@mixin type-lead() {
  font-family:                  $type-base-family;
  font-size:                    $type-lead-size;
  font-weight:                  $type-lead-weight;
  letter-spacing:               $type-lead-letter-spacing;
  line-height:                  $type-lead-line-height;
  margin:                       $type-lead-margin;
}

@mixin type-body() {
  font-family:                  $type-base-family;
  font-size:                    $type-body-size;
  font-weight:                  $type-body-weight;
  letter-spacing:               $type-body-letter-spacing;
  line-height:                  $type-body-line-height;
  margin:                       $type-body-margin;
  text-transform:               inherit;
}

@mixin type-th() {
  font-family:                  $type-base-family;
  font-size:                    $type-th-size;
  font-weight:                  $type-th-weight;
  letter-spacing:               $type-th-letter-spacing;
  line-height:                  $type-th-line-height;
  margin:                       $type-th-margin;
  text-transform:               uppercase;
}

@mixin type-btn() {
  font-family:                  $type-heading-family;
  font-size:                    $type-btn-size;
  font-weight:                  $type-btn-weight;
  letter-spacing:               $type-btn-letter-spacing;
  line-height:                  $type-btn-line-height;
  margin:                       $type-btn-margin;
  text-transform:               uppercase;
  text-decoration:              none;
}

@mixin type-activity() {
  font-family:                  $type-base-family;
  font-size:                    $type-activity-size;
  font-weight:                  $type-activity-weight;
  letter-spacing:               $type-activity-letter-spacing;
  line-height:                  $type-activity-line-height;
  margin:                       $type-activity-margin;
}

@mixin type-symbol() {
  font-family:                  $type-base-family;
  font-size:                    $type-symbol-size;
  font-weight:                  $type-symbol-weight;
  letter-spacing:               $type-symbol-letter-spacing;
  line-height:                  $type-symbol-line-height;
  margin:                       $type-symbol-margin;
  text-transform:               uppercase;
}


// Grid

@mixin grid-row {
  display:                      flex;
  flex-direction:               row;
  width:                        100%;
  box-sizing:                   border-box;
  flex-wrap:                    wrap;
}

@mixin grid-col($col, $sum) {
  width:                        decimal-floor(percentage(math.div($col, $sum)));
  box-sizing:                   border-box;
  vertical-align:               top;
}

@mixin grid-push($col, $sum) {
  margin-left:                  decimal-floor(percentage(math.div($col, $sum)));
}


// Hiding

@mixin hide-text {
  text-indent:                  200%;
  white-space:                  nowrap;
  overflow:                     hidden;
}


// Components

@mixin textfield-appearance {
  width:                        100%;
  box-sizing:                   border-box;
  font-size:                    inherit;
  background-color:             $white;
  padding:                      $ui-space-base * 1.5 $ui-space-base * 2;
  border:                       $input-border-width solid rgba(0, 0, 0, 0.2);
  border-radius:                4px;
  outline:                      none;
  appearance:                   none;

  @include type-body;

  &:focus {
    padding:                    (($ui-space-base * 1.5) - 1) (($ui-space-base * 2) - 1);
    border:                     $input-focus-border-width solid $electricberry;

    + .label {
      color:                    $electricberry;
    }
  }

  &.invalid {
    border-color: $light-red;
    + .label {
      color: $light-red;
    }

    &:focus {
      border-color: $light-red;
    }
  }
}

@mixin card-appearance {
  border:                       $ui-border-width solid $ui-border-color;
  background-color:             $white;
}