.copytockipboard {
  position: relative;
  cursor: pointer;
  .cakepool-tooltip-wrap {
    position: absolute;
    left: 50%;
    top: -50px;
    opacity: 0;
    transition: all .25s;
    .cakepool-tooltip {
      display: block;
      padding: 10px;
      cursor: default;
      position: relative;
      left: -50%;
      white-space: nowrap;
    }
  }
  &:hover .cakepool-tooltip-wrap {
    opacity: 1;
  }
}

