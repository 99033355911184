.input-group {
  .password-mask-toggle {
    position: absolute;
    right: 0;
    top: 0;
    height: 50px;
    padding-left: 16px;
    padding-right: 16px;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border: 0;
    background-color: transparent;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    cursor: pointer;
    img {
      width: 32px;
      height: 32px;
      fill: currentColor;
    }
    .icon-container {
      svg {
        width: 24px;
      }
    }
  }
}
