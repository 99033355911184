.status-page {
  .status-page__coin-status-card {
    overflow: visible;
  }

  .status-page__coin-status-nodes {
    max-height: auto;
    overflow: hidden;
    line-height: 16px;
    @include media("<=phone") {
      margin: auto;
    }
  }

  .status-page__popover {
    // obsolete
    visibility: visible;
    position: relative;
    width: 352px;
  }
  .status-page__tooltip {
    width: 352px;
  }
  .status-page__pagination-button {
    display: inline-block;
    height: 32px;
    width: 32px;
    color: $electricberry;
    border-radius: 6px;
    border: 1px solid #efefef;
    margin: 4px;
    text-align: center;
    box-shadow: $shadow-elevation1;
    cursor: pointer;
    fill: $electricberry;

    &.status-page__pagination-button--disabled {
      color: #efefef;
      cursor: auto;

      fill: #efefef;
    }
  }
  .status-page__coin-status-information {
    padding: 10px 0;
  }

  .status-page__coin-icon {
    padding: 20px;
    width: 32px;
  }

  .status-page__slide-container {
    position: relative;
    width: 100%;
  }
  .status-page__slide {
    margin-left: -100%;
    transition-timing-function: cubic-bezier(0.455, 0.03, 0.515, 0.955); // easeInOutQuad
    position: relative;
    width: 300%;
    .status-page__node {
      display: inline-block;
      width: 33.33%;
    }
    &.status-page__slide--sliding-left {
      margin-left: -200%;
      transition: 0.5s;
    }
    &.status-page__slide--sliding-right {
      margin-left: 0%;
      transition: 0.5s;
    }
  }

  .coin-status .coin-status-information {
    min-width: initial;
  }
}
