/* eslint-disable */
body {
  padding-bottom: 0;
}

.white-bg {
  background-color: #fff;
}

@include media("<desktop") {
  .lead,.text-secondary {
    font-size: 16px;
  }
  .btn {
    font-size: 14px;
  }
}
.card.coin-listing {
  flex-direction: row;
}

.promo-code-desc {
  font-size: 16px;
  text-align: center;
}

.promo-code-input {
  display: flex;
}

.coin-listing.card {
  padding: 16px 0;
  overflow: inherit;
}

.coin-listing .coin-listing-stat-value .sup {
  margin-left: 15px;
  font-weight: bold;
}

.close-button svg {
  top: 0;
  left: 0;
}

.text-underline {
  text-decoration: underline;
}

.coin-status.card {
  flex-direction: row;
}

.coin-status {
  .coin-status-node {
    display: inline-block;
    margin: 4px;
    cursor: pointer;

    &.green-50 {
      background-color: rgba($green, 0.5);
    }
  }
}

.icon__container {
  position: relative;
}

.side-nav li {
  padding: 10px 0;
}

body > #root > header,
body > #root > footer,
main > #root > section {
  padding-left: $ui-space-base * 3;
  padding-right: $ui-space-base * 3;
  box-sizing: border-box;

  @include media(">desktop") {
    padding-left: $ui-space-base * 4;
    padding-right: $ui-space-base * 4;
  }

  &.no-padding {
    padding-left: 0;
    padding-right: 0;
  }
}

body > #root > header {
  width: 100%;
  height: $main-header-height;
  padding: 0 $ui-space-base;
  display: flex;
  align-items: center;
  position: fixed;
  justify-content: space-between;
  bottom: 0;
  z-index: $zindex-fixed + 10;
  position: static;
  padding: 0 $ui-space-base * 2 0 $ui-space-base * 25;
  height: $ui-space-base * 10;

  .user-menu {
    display: flex;
    align-items: center;
    margin-left: auto;
    .link {
      padding: 16px;
    }
  }

  .language-menu {
    display: flex;
    align-items: center;
  }

  .language-logout-menu {
    display: flex;
    align-items: center;
    .logout-wrapper {
      margin-left: 24px;
    }
  }

  .mobile-obfuscate,
  .desktop-user-menu,
  .profile-language-menu,
  .mobile-menu {
    display: none;
  }

  @media(max-width: 1658px) {
    .desktop-user-menu {
      display: flex;
      align-items: center;
      margin-left: auto;
    }
    .user-menu,
    .language-menu {
      display: none;
    }
    .profile-language-menu,
    .mobile-language-switch {
      display: block;
      padding: 6px;
    }
  }

  @media(max-width: 1480px) {
    /* background-color:           $cardboard; */
    /* border-top:                 $ui-border-width solid $ui-border-color; */
    .mobile-menu {
      display: flex;
      align-items: center;
      margin-left: auto;
    }
    .desktop-user-menu,
    .user-menu,
    .main-menu,
    .profile-language-menu,
    .language-menu {
      display: none;
    }
    .fiat-buy-menu {
      order: 2;
    }
    .profile-menu {
      order: 3;
    }
    .mobile-language-switch {
      padding: 6px;
    }
    .mobile-obfuscate {
      display: flex;
    }
  }

  @include media(">tablet") {
    /* position:                   static; */
    /* padding:                    0 $ui-space-base * 2 0 $ui-space-base * 25; */
    /* height:                     $ui-space-base * 10; */
  }

  .btn {
    margin: $ui-space-base * 2;
    min-width: $ui-space-base * 10;
  }

  .user-picture {
    padding: $ui-space-base * 0.5;
    margin: $ui-space-base * 0.5;

    @include media(">desktop") {
      margin: $ui-space-base * 1.5;
    }

    img {
      width: $ui-space-base * 5;
      height: $ui-space-base * 5;
      border-radius: 50%;
      display: block;
    }
  }
}

body > #root > main {
  @include media(">tablet") {
    margin-top: 0;
  }

  section,
  hr {
    margin-bottom: $ui-space-base * 2;
  }

  hr {
    margin-top: $ui-space-base * 4;
  }

  hr.unset {
    margin-top: 0;
    margin-bottom: 0;
  }

  > article > header {
    border-bottom: $ui-border-width solid $ui-border-color;
  }
  > article > section,
  > article > footer {
    margin-bottom: 0;
    background-color: $white;
    border-bottom: $ui-border-width solid $ui-border-color;
  }
}

body > #root > footer {
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: center;

  @include media("<tablet") {
    padding-bottom: $ui-space-base * 12;
  }
}

.main-logo {
  background-image: none;
}


.icon-small {
  vertical-align: sub;
  &.icon-small-expand-more {
    background-image: url($svg-icon-expand-more);
    cursor: pointer;
  }

  &.icon-small-expand-less {
    background-image: url($svg-icon-expand-less);
    cursor: pointer;
  }
}

.coin-status-popover {
  p {
    @include type-activity();
    padding: 4px 16px;
    line-height: 2.4rem;
    display: flex;
    flex-wrap: wrap;
  }
}

.table {
  &__clickable-row {
    cursor: pointer;
    &:hover {
      background: rgba($electricberry, 0.4);
      color: white;
    }
  }
}

.kyc-lite-verification, .kyc-full-verification {
  margin-bottom: 95px;
}

.kyc-full-verification {
  & .btn {
    margin-top: 30px;
  }

  input:read-only {
    background-color: lightyellow;
  }
}

.kyc-rejection {
  background-color: #ffeeee;
  border: 2px solid #b20000;
  border-radius: 8px;
  padding: 15px;
}

.kyc-in-progress {
  background-color: $highlightyellow;
  border: 1px solid $grey;
  border-radius: 8px;
  padding: 15px;
}

.intl-tel-input .selected-flag {
  width: 110px !important;
}

.intl-tel-input input[type=tel] {
  padding-left: 120px !important;
}

.react-datepicker__navigation--years-upcoming {
  border-bottom-color: #000 !important;
  top: -8px;
  padding-top: 10px;
  margin-bottom: 5px;
}

.react-datepicker__navigation--years-previous {
  border-top-color: #000 !important;
  top: 8px;
  margin-top: 5px;
  padding-bottom: 10px;
}

.react-datepicker__year-read-view--down-arrow, .react-datepicker__month-read-view--down-arrow, .react-datepicker__month-year-read-view--down-arrow {
  border-top-color: #000;
}

.react-datepicker__portal .react-datepicker__navigation--previous {
  border-right-color: #000;
}

.react-datepicker__portal .react-datepicker__navigation--next {
  border-left-color: #000;
}

.poa-tooltip {
  margin-left: 8px;
}

.poa-tooltip-content {
  padding: 24px !important;
  max-width: 700px;
}

.kyc-full-upload input[type="file"] {
  /* visibility: hidden etc. wont work */
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
}
.kyc-full-upload input[type="file"]:focus + label {
  /* keyboard navigation */
  outline: 1px dotted #000;
  outline: -webkit-focus-ring-color auto 5px;
}
.kyc-full-upload input[type="file"] + label * {
  pointer-events: none;
}

.kyc-full-upload-label {
  display: flex;
  flex-direction: column;

  :first-child {
    margin-bottom: $ui-space-base;
  }
}

.kyc-help-text-wrapper {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 15px;
}

.kyc-modal-cta-buttons {
  display: flex;
  justify-content: flex-end;
  :first-child {
    margin-right: 20px;
  }
}

.kyc-full-upload .btn {
  margin-bottom: 25px;
}

.allocation-preferences{

  .radio-button{
    padding: $ui-space-base;
  }

  @include media(">tablet") {

    .content{
      padding-bottom: $ui-space-base * 2;
      display:flex;
    }

    .description{
      width: 33.333333333%;
      padding-right: 16px;
    }
  }

  @include media("<tablet") {

    .content{
      padding-bottom: $ui-space-base * 2;
    }

    .description{
      padding-bottom: $ui-space-base * 2;
    }
  }
}

.override-settings-button {
  padding-top: 8px;

  @include media("<tablet") {
    padding-left: 0px;
  }
}

.profile-dropdown {
  margin-bottom: 25px;

  @media (min-width: 768px) {
    display: flex;
    margin-bottom: 0;
  }

  & span {
    margin-right: 25px;
    margin-top: 0;
    width: 250px;

    @media (min-width: 768px) {
      margin-top: 5px;
    }
  }

  & .select {
    margin-bottom: 0;
    min-width: 250px;
  }

  & .select .select__text {
    font-weight: bold;
    padding: 5px 10px;
    height: inherit;
  }

  .select .select__options {
    top: 36px !important;
  }

  .select .select__option {
    height: inherit;
  }
}

@include media(">tablet") {
.kyc-full-upload {
  display: flex;
  justify-content: space-between;

  > div {
    display: flex;
  }

  & span {
    margin-right: 30px;
  }

  & label {
    width: max-content;
    height: fit-content;
    margin-top: 0 !important;
    margin-bottom: 30px;
    margin-left: 30px;
  }

  .upload-file-name {
    padding-top: 10px;
    width: max-content;
  }

  .kyc-full-upload .btn {
    margin-bottom: 0;
  }

  .kyc-help-text-wrapper {
    margin-bottom: 0;
  }

  .input-group {
    width: 1px;
  }
}
}

@include media("<tablet") {
  .form-field-label {
    position: relative;
    top: -15px !important;
  }

  .fiat-buy-menu {
    & > .btn {
      margin: 16px 0 !important;
    }
  }

  .mobile-language-switch {
    & .dropdown__options-container {
      left: 0;
      right: auto !important;
    }
  }
}

.referral-id-profile {
  display: -webkit-inline-box;
}
td.led-green {
  display: block;
  margin: 0 auto;
  width: 24px;
  height: 24px;
  background-color: #ABFF00;
  border-radius: 50%;
  box-shadow: rgba(0, 0, 0, 0.2) 0 -1px 7px 1px, inset #304701 0 -1px 9px, #89FF00 0 2px 12px;
}

td.led-red {
  display: block;
  margin: 0 auto;
  width: 24px;
  height: 24px;
  background-color: #F00;
  border-radius: 50%;
  box-shadow: rgba(0, 0, 0, 0.2) 0 -1px 7px 1px, inset #441313 0 -1px 9px, rgba(255, 0, 0, 0.5) 0 2px 12px;
  -webkit-animation: blinkRed 0.5s infinite;
  -moz-animation: blinkRed 0.5s infinite;
  -ms-animation: blinkRed 0.5s infinite;
  -o-animation: blinkRed 0.5s infinite;
  animation: blinkRed 0.5s infinite;
}

@-webkit-keyframes blinkRed {
    from { background-color: #F00; }
    50% { background-color: #A00; box-shadow: rgba(0, 0, 0, 0.2) 0 -1px 7px 1px, inset #441313 0 -1px 9px, rgba(255, 0, 0, 0.5) 0 2px 0;}
    to { background-color: #F00; }
}
@-moz-keyframes blinkRed {
    from { background-color: #F00; }
    50% { background-color: #A00; box-shadow: rgba(0, 0, 0, 0.2) 0 -1px 7px 1px, inset #441313 0 -1px 9px, rgba(255, 0, 0, 0.5) 0 2px 0;}
    to { background-color: #F00; }
}
@-ms-keyframes blinkRed {
    from { background-color: #F00; }
    50% { background-color: #A00; box-shadow: rgba(0, 0, 0, 0.2) 0 -1px 7px 1px, inset #441313 0 -1px 9px, rgba(255, 0, 0, 0.5) 0 2px 0;}
    to { background-color: #F00; }
}
@-o-keyframes blinkRed {
    from { background-color: #F00; }
    50% { background-color: #A00; box-shadow: rgba(0, 0, 0, 0.2) 0 -1px 7px 1px, inset #441313 0 -1px 9px, rgba(255, 0, 0, 0.5) 0 2px 0;}
    to { background-color: #F00; }
}
@keyframes blinkRed {
    from { background-color: #F00; }
    50% { background-color: #A00; box-shadow: rgba(0, 0, 0, 0.2) 0 -1px 7px 1px, inset #441313 0 -1px 9px, rgba(255, 0, 0, 0.5) 0 2px 0;}
    to { background-color: #F00; }
}

.mt-32-mobile {
  @include media("<tablet") {
    margin-top: $ui-space-base * 4;
  }
}

#launcher {
  margin-bottom: 0px !important;
  z-index: 1039 !important;
}

.wechat-container {
  display: flex;
  flex-direction: row;
  .text {
    display: flex;
    align-items: center;
    padding-right: 12px;
  }
}

.wechat-tooltip {
  position: relative;
  display: flex;

  .img-qr {
    width: 180px;
  }
  img {
    vertical-align: middle;
  }
}

.wechat-tooltip .tooltip-img {
  visibility: hidden;
  text-align: center;
  padding: 2px 2px;
  background: white;
  border: 1px solid rgba(0,0,0,0.2);
  border-radius: 4px;
  bottom: 120%;
  left: 50%;
  margin-left: -95px;
  filter: drop-shadow(0 8px 16px rgba(0,0,0,0.4),);
  position: absolute;
}

.wechat-tooltip .tooltip-img::after {
  content: "";
  position: absolute;
  border: 10px solid;
  border-color: #fff transparent transparent;
  bottom: -20px;
  left: calc(50% - 10px);
}

.wechat-tooltip:hover .tooltip-img {
  visibility: visible;
}

.swiper-pagination-bullet-active {
  background-color: #5B10FF !important;
}

.swiper-pagination-bullet {
  margin: 6px 5px 0px 5px;
  cursor: pointer;
  width: 8px !important;
  height: 8px !important;
}

.swiper-slide {
  height: auto !important;
  margin-bottom: 18px;
}

.grecaptcha-badge {
  z-index: 1;
  bottom: 85px !important;
}

body.authenticated {
  #launcher {
    visibility: hidden;
  }
}

/* fix flag display for greece, ghana, grenada in PhoneNumberInput.js from react-intl-tel-input library */
.iti-flag.gr, .iti-flag.gh, .iti-flag.gd {
  padding: 0;
}

/* CMS Editor content */
.cms-editor-content {
  .custom-item {
    margin-bottom: 10px;
  }

  ol {
    list-style-type: none;
    padding-left: 20px;
    counter-reset: section;

    li {
      display: block;
      position: relative;
      margin: 0;
      padding: 0;
      padding-left: 40px;

      p {
        margin-bottom: 10px;
      }

      &::before {
        position: absolute;
        left: 0;
        counter-increment: section;
        content: counter(section) ".";
        padding-right: 5px;
      }
    }

    ol {
      counter-reset: subsection;

      li {
        &::before {
          counter-increment: subsection;
          content: counter(section) "." counter(subsection) " ";
        }
      }

      ol {
        counter-reset: subsubsection;

        li {
          padding-left: 60px;

          &::before {
            counter-increment: subsubsection;
            content: counter(section) "." counter(subsection) "." counter(subsubsection) " ";
          }
        }
      }
    }
  }
}


.react-pdf__Page {
  canvas {
    position: relative;
    left: 50%;
    transform: translate(-50%);
  }
}
