.onboarding {
  &__overlay {
    cursor: pointer;
    height: 1056px;
    pointer-events: auto;
    bottom: 0px;
    left: 0px;
    overflow: hidden;
    position: absolute;
    right: 0px;
    top: 0px;
    z-index: 10000;
    background-color: rgba(0, 0, 0, 0.5);
    mix-blend-mode: hard-light;
  }

  &__spotlight {
    border-radius: 4px;
    position: absolute;
    background-color: gray;
    opacity: 1;
    pointer-events: auto;
    transition: opacity 0.2s ease 0s;
  }

  &__modal {
    background: white;
    border-radius: 8px;
    margin: auto;
    padding: 16px;
    max-width: 500px;
    position: absolute;
    transition: 0.3s ease;
    z-index: 10000;
    will-change: transform;

    footer {
      display: flex;
      justify-content: space-between;
    }
  }

  &__floating-container {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(0,0,0,0.5);
  }

  &__startmodal {
    footer {
      display: flex;
      justify-content: space-between;
    }
  }
}
