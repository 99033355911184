// Icon thumbnails

.coin-icon,
.activity-icon {
  flex-grow: 0;
  width: $ui-icon-width;
  height: $ui-icon-width;
  display: flex;
  justify-content: center;
  align-items: center;

  svg {
    width: $ui-icon-width;
    height: $ui-icon-width;
    fill: currentColor;
  }
}

.coin-icon {
  color: $faded;
}

.activity-icon {
  color: $text-secondary;
}

// Progress

progress {
  appearance: none;
  border-radius: 6px;
  height: 12px;

  &::-webkit-progress-bar {
    background-color: rgba($electricberry, 0.1);
    border-radius: 6px;
    height: 12px;
  }

  &::-webkit-progress-value {
    background-color: $electricberry;
    border-radius: 6px;
    height: 12px;
  }
}

// Wallet summary

.wallet-summary {
  color: $text-default;
  @include type-activity();
  padding: 0;
  position: relative;

  &__tooltips {
    position: absolute;
    margin-top: 16px;
    margin-left: -120px;
    min-width: 350px;
    display: none;
    padding: 16px;

    background-color: #fff;
    border: 0.5px solid rgba(0, 0, 0, 0.16);
    border-radius: 8px;
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.16);

    z-index: $zIndex-wallet-summary;
  }

  &__tooltips &__content {
    background: red;
  }

  &:hover &__tooltips {
    top: 100%;
    display: block;
  }

  &__total-balance {
    display: flex;
    align-items: center;
  }

  span {
    padding: 0;
  }

  > span {
    margin-left: 8px;
  }

  .icon-small {
    vertical-align: middle;
    opacity: 0.5;
  }
}
.wallet-tooltips-content {
  display: flex;

  &__label {
    line-height: 24px;
    flex: 2;
  }
  &__value {
    line-height: 24px;
    flex: 1.5;
  }
}

// Oven

.oven {
  position: relative;
}

.oven-tools {
  position: sticky;
  box-sizing: border-box;
  width: 100%;
  height: $oven-cell-height;
  top: 0;
  z-index: $zindex-fixed + 10;
  padding-right: $ui-space-base * 3;
  margin-bottom: -$oven-cell-height;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  overflow: hidden;

  @include media(">desktop") {
    position: relative;
    height: $oven-cell-height-desktop;
    padding-right: $ui-space-base * 4;
    margin-bottom: -$oven-cell-height-desktop;
  }

  > * {
    margin-left: $ui-space-base;
  }
}

.oven-search-field {
  position: absolute;
  left: 100%;
  right: auto;
  width: 100%;
  height: $ui-space-base * 5;
  max-width: $ui-space-base * 30;
  padding: 0 $ui-space-base * 0.5;
  background-color: $white;
  border: $ui-border-width solid $ui-border-color;
  border-radius: $ui-space-base;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  transition: all 0.3s ease;

  svg {
    flex-shrink: 0;
  }

  g {
    stroke: $faded;
  }

  input[type="search"] {
    appearance: none;
    border: 0;
    width: calc(100% - #{$ui-icon-width * 2});

    @include type-body;

    margin: 0;
  }
}

.oven-search.active {
  display: none;
  ~ .oven-search-field {
    left: auto;
    right: $ui-space-base * 2.5;
  }
}

.oven table {
  width: 100%;
  max-width: 100%;
  border-collapse: collapse;
  border-spacing: 0;
  empty-cells: show;
  position: relative;

  tr,
  th,
  td {
    box-sizing: border-box;
  }

  th,
  td {
    padding-top: 0;
    padding-bottom: 0;
    padding-left: $ui-space-base * 1.5;
    padding-right: $ui-space-base * 1.5;

    &:first-child {
      padding-left: $ui-space-base * 3;
    }

    &:last-child {
      padding-right: $ui-space-base * 3;
    }

    @include media(">desktop") {
      padding-left: $ui-space-base * 2;
      padding-right: $ui-space-base * 2;

      &:first-child {
        padding-left: $ui-space-base * 4;
      }

      &:last-child {
        padding-right: $ui-space-base * 4;
      }
    }

    // Responsive tables

    &.stackable {
      @include media("<desktop") {
        padding-left: $ui-space-base * 3;
        padding-right: $ui-space-base * 3;
        width: 100%;
        max-width: 100%;
        box-sizing: border-box;
        display: block;
        clear: left;
        text-align: left;
      }
    }

    &:not(.stackable) {
      @include media("<desktop") {
        display: none;
      }
    }

    &.stackable-first {
      @include media("<desktop") {
        padding-top: $ui-space-base * 2;
      }
    }

    &.stackable-last {
      @include media("<desktop") {
        padding-bottom: $ui-space-base * 2;
      }
    }

    &.icon {
      white-space: nowrap;
      width: 1px;

      @include media("<desktop") {
        width: $ui-icon-width;
        display: block;
        position: absolute;
        padding: $ui-space-base * 2 $ui-space-base * 3 0;
      }
    }

    &.oven-action {
      white-space: nowrap;

      @include media("<desktop") {
        padding-top: $ui-space-base;
      }

      a:nth-child(n + 2) {
        margin-left: $ui-space-base * 2;

        @include media(">desktop") {
          margin-left: $ui-space-base * 3;
        }
      }
    }
  }

  th.stackable {
    @include media("<desktop") {
      display: flex;
      align-items: center;
      position: sticky;
      top: 0;
      z-index: $zindex-fixed;
      background-color: $cardboard;
      border-bottom: $ui-border-width solid $ui-border-color;
    }
  }

  td.stackable {
    @include media("<desktop") {
      text-indent: $ui-space-base * 7;
      height: $ui-space-base * 3;
    }
  }

  td.stackable-first {
    @include media("<desktop") {
      height: $ui-space-base * 6;
    }
  }

  td.stackable-last {
    @include media("<desktop") {
      height: $ui-space-base * 6;
    }
  }

  .stackable-visible {
    display: none;
    font-family: $type-base-family;

    @include media("<desktop") {
      display: inline;
    }
  }

  thead {
    tr {
      border-bottom: $ui-border-width solid $ui-border-color;

      @include media("<desktop") {
        position: sticky;
        top: 0;
        z-index: $zindex-fixed;
        background-color: $cardboard;
        border-bottom: 0;
      }
    }

    th {
      height: $oven-cell-height;

      &:nth-child(n + 2) {
        color: $text-secondary;

        @include type-th();
      }

      @include media("<desktop") {
        &:nth-child(n + 2) {
          display: none;
        }
      }

      @include media(">desktop") {
        height: $oven-cell-height-desktop;
      }
    }
  }

  tbody {
    background-color: $white;

    &.has-progress {
      background-color: transparent;
    }

    tr {
      border-bottom: $ui-border-width solid $ui-border-color;
    }

    td {
      white-space: nowrap;
      position: relative;

      @include media(">desktop") {
        height: $oven-cell-height-desktop;
      }
    }
  }
}

.oven-coin-name {
  @include type-lead();
}

.oven-show-more {
  background-color: $white;
  height: $oven-cell-height;
  border-bottom: $ui-border-width solid $ui-border-color;
  display: flex;
  justify-content: center;
  align-items: center;

  @include media(">desktop") {
    height: $oven-cell-height-desktop;
  }
}

.oven-switch {
  display: flex;
  align-items: center;

  @include type-body;

  margin: 0;

  @include media(">desktop") {
    height: $oven-cell-height-desktop;
  }

  .switch {
    margin-left: $ui-space-base;
  }

  a {
    margin-left: $ui-space-base;
    margin-right: $ui-space-base;
  }
}

.oven-mode-toggle {
  position: absolute;
  right: $ui-space-base * 2.5;
  top: 0;
  z-index: $zindex-fixed + 10;
  display: flex;
  align-items: center;
  height: $oven-cell-height;

  @include media(">desktop") {
    height: $oven-cell-height-desktop;
  }

  a {
    display: flex;
    justify-content: center;
    align-items: center;
    width: $ui-icon-width;
    height: $ui-icon-width;
    margin-left: $ui-space-base * 2;
    color: $faded;
  }

  svg {
    width: $ui-icon-width;
    height: $ui-icon-width;
    fill: currentColor;
  }

  @include media(">desktop") {
    display: none;
  }
}

.oven-mode-normal .oven-mode-toggle-normal,
.oven-mode-compact .oven-mode-toggle-compact {
  color: $green;
}

.oven-mode-compact table {
  @include media("<desktop") {
    td.compactable {
      width: 30%;
      float: left;
      clear: none;
      text-indent: 0;

      .stackable-visible {
        display: none;
      }
    }

    td.compactable-first {
      text-indent: $ui-space-base * 7;
    }

    td.compactable-20 {
      width: 20%;
    }
    td.compactable-25 {
      width: 25%;
    }
    td.compactable-30 {
      width: 30%;
    }
    td.compactable-40 {
      width: 40%;
    }
    td.compactable-50 {
      width: 50%;
    }
    td.compactable-60 {
      width: 60%;
    }
  }
}

.oven-progress-bars {
  position: absolute;
  top: $ui-space-base * 8;
  left: 0;
  z-index: -1;
  width: 100%;
  background: linear-gradient(to right, rgba($electricberry, 0) 0%, rgba($electricberry, 0.1) 100%), $white;

  @include media(">desktop") {
    top: $ui-space-base * 12;
  }

  .oven-progress-bar {
    width: 100%;
    height: 168.5px;
    box-sizing: border-box;

    @include media(">desktop") {
      height: $ui-space-base * 12;
    }
  }
}

.oven-mode-compact .oven-progress-bars .oven-progress-bar {
  @include media("<desktop") {
    height: 120.5px;
  }
}

.coin-listing {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;

  @include media("<desktop") {
    margin-bottom: 0;
  }

  .card & {
    margin-bottom: 0;
    border-bottom: $ui-border-width solid $ui-border-color;

    &:last-child {
      border: 0;
    }
  }

  &.no-border-bottom{
    border-bottom: none;
  }

  .coin-icon,
  .coin-listing-date {
    min-width: 80px;
    width: 80px;
    height: 80px;

    @include media(">desktop") {
      min-width: 80px;
      width: 96px;
      height: 96px;
    }
  }

  .activity-icon {
    min-width: 32px;
    width: 32px;
    height: 80px;

    @include media(">desktop") {
      min-width: 80px;
      width: 96px;
      height: 96px;
    }
  }

  .coin-listing-information {
    display: flex;
    flex-grow: 3;
    align-content: center;
    align-items: center;

    @include media("<desktop") {
      min-height: 80px;
      min-width: 200px;
    }

    @include media(">desktop") {
      min-height: 96px;
      width: 192px;
    }

    progress {
      width: 108px;
      margin-right: 8px;
      vertical-align: middle;
    }
  }

  .coin-listing-stats {
    display: flex;
    flex-grow: 2;
    align-content: center;
    align-items: center;
    flex-wrap: wrap;
  }

  .coin-listing-stat {
    box-sizing: border-box;
    display: flex;
    align-content: center;
    align-items: center;

    @include media("<desktop") {
      margin-left: 96px;
    }

    @include media(">desktop") {
      min-height: 96px;
      margin-left: 0;
      padding-top: 16px;
      padding-bottom: 16px;
    }
  }

  .coin-listing-stat-label {
    @include type-activity();
    color: $text-secondary;
    line-height: 2rem;
    width: 80px;
    padding-right: 16px;
    word-break: break-word;
  }

  .coin-listing-stat-value {
    margin: 0;
    font-family: $type-mono-family;

    @include media(">desktop") {
      width: 195px;
      text-align: right;
      padding-right: 16px;
    }

    .sup {
      @include type-activity();
      font-weight: 500;
      vertical-align: 1.6rem;
    }
  }

  .coin-listing-amount {
    font-family: $type-mono-family;
    font-weight: 400;
    box-sizing: border-box;
    margin: 0;
    padding-right: 16px;
    display: flex;
    flex-grow: 2;
    align-content: center;
    align-items: center;
    flex-wrap: wrap;

    @include media("<tablet") {
      margin-left: 96px;
    }

    @include media(">tablet") {
      min-height: 96px;
      margin-left: 0;
      padding-top: 16px;
      padding-bottom: 16px;
      justify-content: flex-end;
    }

    .sup {
      @include type-activity();
      font-weight: 500;
      vertical-align: 1.6rem;
      margin-bottom: 8px;
    }

    [class^="hide-"] {
      font-family: $type-base-family;
      margin-left: 4px;
    }
  }

  .coin-listing-date {
    min-width: 128px;
    box-sizing: border-box;
    display: flex;
    align-content: center;
    align-items: center;

    @include media(">tablet") {
      min-height: 96px;
      margin-left: 0;
      padding-top: 16px;
      padding-bottom: 16px;
    }
  }

  .coin-listing-event {
    min-height: 80px;
    box-sizing: border-box;
    display: flex;
    flex-grow: 2;
    align-content: center;
    align-items: center;

    .pending-label {
      font-size: 0.8em;
    }

    @include media(">tablet") {
      min-height: 96px;
      margin-left: 0;
      padding-top: 16px;
      padding-bottom: 16px;
    }
  }

  .coin-listing-actions {
    padding-right: 16px;
    display: flex;
    align-content: center;
    align-items: center;

    @media (max-width: 1262px) {
      margin-top: 8px;
      margin-bottom: 16px;
      margin-left: 88px;
    }

    // @include media(">desktop") {
    //   min-height: 96px;
    //   margin-left: 0;
    // }

    &.flex-start {
      justify-content: flex-start;
    }

    &.flex-end {
      justify-content: flex-end;
    }

    .btn {
      min-width: 96px;
      margin-left: 8px;
      margin-right: 8px;
    }
  }
}

.icon-small {
  display: inline-block;
  width: 24px;
  height: 24px;
  background-position: center center;
  background-size: 100%;
  background-repeat: no-repeat;

  &.icon-small-return {
    background-image: url($svg-icon-small-return);
  }
}

// Shares Listing

#shares-for-sale {
  @include media("<desktop") {
    .container {
      padding: 0;
    }
    .card {
      border-radius: 0;
    }
  }
}

// Wallet

#wallet-balances {
  @include media("<desktop") {
    > .container {
      padding: 0;
    }
    .card {
      border-radius: 0;
    }
  }

  .coin-listing-information {
    width: calc(100% - 96px);
    margin-bottom: -48px;

    @include media(">tablet") {
      width: calc(100% - 112px);
    }

    @include media(">hd") {
      min-width: 192px;
      width: auto;
    }
  }

  .wallet-balance-coins,
  .wallet-balance-shares,
  .wallet-balance-returns {
    width: 100%;

    @include media(">tablet") {
      margin-left: 112px;
      padding-top: 8px;
      padding-bottom: 8px;
      width: calc(50% - 112px);
      min-height: auto;
      justify-content: flex-start;
    }

    @include media(">hd") {
      margin-left: 0;
      padding-top: 16px;
      padding-bottom: 16px;
      width: auto;
      min-height: 96px;
      justify-content: flex-end;
    }
  }

  .coin-listing-actions {
    @include media(">tablet") {
      min-height: auto;
    }

    @include media(">hd") {
      min-height: 96px;
    }
  }

  .wallet-balance-returns {
    @include media("<hd") {
      margin-bottom: 16px;
    }
  }
}

#wallet-activity {
  @include media("<desktop") {
    > container {
      padding: 0;
    }
    .card {
      border-radius: 0;
    }
  }

  .coin-listing {
    width: 100%;
    padding-left: 32px;
    padding-right: 32px;
    @include media("<tablet") {
      padding-bottom: 16px;
    }
  }

  .coin-listing-event,
  .coin-listing-amount {
    @include media("<tablet") {
      width: calc(100% - 300px);
    }

    @include media(">tablet") {
      width: 20%;
    }
  }

  .coin-listing-amount {
    @include media("<tablet") {
      margin-left: 176px;
    }
  }
}

// Status

#coin-status {
  .container {
    @include media("<tablet") {
      padding: 0;
    }
  }
}

.coin-status {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;

  @include media("<tablet") {
    flex-wrap: wrap;
  }

  .coin-icon {
    min-width: 80px;
    width: 80px;
    height: 80px;

    @include media(">tablet") {
      min-width: 80px;
      width: 96px;
      height: 96px;
    }
  }

  .coin-status-information {
    display: flex;
    flex-grow: 3;
    align-content: center;
    align-items: center;

    @include media("<tablet") {
      min-height: 80px;
      min-width: 300px;
    }

    @include media(">tablet") {
      min-height: 96px;
    }
  }

  .coin-status-nodes {
    display: inline-block;
    width: 260px;
    box-sizing: border-box;
    margin-left: 88px;
    padding: 16px 0px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    align-content: center;
  }

  .coin-status-node {
    width: $ui-space-base * 2;
    height: $ui-space-base * 2;
    border-radius: $ui-space-base;
    margin-left: $ui-space-base*0.5;
    margin-right: $ui-space-base*0.5;
    margin-top: $ui-space-base*0.5;
    margin-bottom: $ui-space-base*0.5;

    @include media(">tablet") {
      margin-left: $ui-space-base;
      margin-right: $ui-space-base;
      margin-top: $ui-space-base;
      margin-bottom: $ui-space-base;
    }

    &.green {
      background-color: $green;

      &:hover {
        border: 2px solid darken($green, 10%);
      }
    }

    &.orange {
      background-color: $orange;

      &:hover {
        border: 2px solid darken($orange, 10%);
      }
    }
  }
}

.coin-status-popover {
  max-width: 352px;

  dl {
    @include type-activity();
    padding: 4px 16px;
    line-height: 2.4rem;
    display: flex;
    flex-wrap: wrap;

    dt {
      display: block;
      width: 128px;
    }

    dd {
      margin: 0;
      width: 176px;
      display: block;
    }
  }
}

.button-disabled-popover {
  max-width: 352px;
  dl {
    @include type-activity();
    padding: 4px 16px;
    line-height: 2.4rem;
    display: flex;
    flex-wrap: wrap;

    dt {
      display: block;
    }
  }
}

.balance-tooltip {
  max-width: 352px;
  dl {
    @include type-activity();
    padding: 4px 16px;
    line-height: 2.4rem;
    display: flex;
    flex-wrap: wrap;

    dt {
      display: block;
    }
  }
}

// Close button

.close-button {
  display: block;
  width: $ui-space-base * 6;
  height: $ui-space-base * 6;
  background-color: $electricberry;
  background-repeat: no-repeat;
  background-position: center center;
  border-radius: $ui-space-base * 3;
  box-shadow: $shadow-elevation1;
  color: $white;

  @include hide-text;

  @include media(">desktop") {
    width: $ui-space-base * 5;
    height: $ui-space-base * 5;
    border-radius: $ui-space-base * 2.5;
  }

  svg {
    position: absolute;
    left: 4px;
    top: 4px;
    width: $ui-icon-width;
    height: $ui-icon-width;
    fill: currentColor;
  }
}

// Modal

.modal {
  display: none;
  overflow-x: hidden;
  overflow-y: auto;
  height: 100%;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: $zindex-modal;
  outline: 0;

  &.open {
    display: block;
  }

  .modal-wrapper {
    max-width: map-get($breakpoints, desktop) - $ui-space-base * 6;
    margin: $ui-space-base * 2;
    position: relative;
    z-index: $zindex-modal;
    border: $ui-border-width solid $ui-border-color;
    border-radius: $ui-border-radius;
    box-shadow: $shadow-elevation2;
    background-color: $white;

    @include media(">desktop") {
      margin: 8rem auto;
    }
  }

  // shrink div to fit content
  .modal-small {
    display: inline-block;
    left: 50%;
    position: relative;
    transform: translateX(-50%);
  }

  .modal-content {
    overflow: hidden;
    border-radius: $ui-border-radius;
  }

  .close-button {
    position: absolute;
    top: -$ui-space-base * 2;
    right: -$ui-space-base * 2;
    z-index: $zindex-tooltip;

    @include media(">desktop") {
      top: -$ui-space-base * 2.5;
      right: -$ui-space-base * 2.5;
    }
  }
}

.modal-overlay {
  width: 100%;
  height: 100%;
  display: none;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: $zindex-modal-backdrop;
  background-color: $text-secondary;
}

body.modal-open {
  overflow: hidden;

  .modal-overlay {
    display: block;
  }
}

// Snack bar

.snacks {
  position: fixed;
  bottom: $ui-space-base * 8;
  width: 100%;

  @include media(">tablet") {
    right: 0;
    bottom: 0;
    margin: $ui-space-base * 2;
    width: auto;
  }
}

.snack-bar {
  background-color: $white;
  display: flex;
  align-items: flex-start;
  padding: $ui-space-base * 2;

  @include media(">tablet") {
    width: $ui-space-base * 41;
    border: $ui-border-width solid $ui-border-color;
    border-radius: $ui-border-radius;
    box-shadow: $shadow-elevation1;
  }

  @include media("<tablet") {
    border-top: $ui-border-width solid $ui-border-color;
  }

  .coin-icon {
    width: $ui-icon-width;
    height: $ui-icon-width;
    color: $faded;

    svg {
      width: $ui-icon-width;
      height: $ui-icon-width;
    }
  }
}

// Switch

.switch {
  padding: $switch-overhang * 0.5;

  input[type="checkbox"] {
    display: none;

    &:disabled + label {
      opacity: 0.6;
      cursor: not-allowed;
      box-shadow: 0 0 0 transparent;

      &:after {
        height: $switch-height;
        width: $switch-height;
      }
    }

    &:checked:disabled + label {
      box-shadow: 0 0 0 $ui-space-base * 1.5 $faded inset;

      &:after {
        transform: translateX($switch-width - $switch-height);
      }
    }

    &:checked + label {
      transition: box-shadow 0.2s ease, border-color 0.2s ease 0.02s;

      box-shadow: 0 0 0 ($switch-width*0.5) rgba($green, 0.16) inset;

      &:after {
        transform: translateX($switch-width - $switch-height);
        background-color: $green;
      }

      &:hover:active {
        &:after {
          transform: translateX($switch-width - $switch-height);
        }
      }
    }
  }

  label {
    background: $faded;
    padding: 0;
    position: relative;
    display: block;
    cursor: pointer;
    border-radius: $switch-height * 0.5;
    transition: box-shadow 0.2s ease 0.02s, border-color 0.2s ease;
    width: $switch-width;
    height: $switch-height;

    &:after {
      background: $white;
      position: absolute;
      top: -($switch-overhang * 0.5);
      left: -($switch-overhang * 0.5);
      content: "";
      transform: translateX(0);
      transition: transform 0.2s ease;
      box-shadow: 0 1px 3px rgba($black, 0.4);
      border-radius: ($switch-height + $switch-overhang) * 0.5;
      height: $switch-height + $switch-overhang;
      width: $switch-height + $switch-overhang;
      min-width: $switch-height + $switch-overhang; // Safari squishes knob without this during transition
    }
  }
}

// Form

form[class^="form-"] {
  margin-bottom: $ui-space-base * 3;

  @include media(">desktop") {
    margin-bottom: $ui-space-base * 4;
  }

  &:last-child {
    margin-bottom: 0;
  }
}

.form-inline {
  @include media(">tablet") {
    display: flex;
    flex-wrap: wrap;
  }

  .input-group {
    margin-bottom: $ui-space-base * 3;
    margin-right: 0;

    @include media(">tablet") {
      margin-bottom: 0;
      margin-right: $ui-space-base * 4;
    }
  }
}

.input-group {
  position: relative;
  margin-bottom: $ui-space-base * 4;

  // Textfield and select labels

  .label {
    white-space: nowrap;
    // Default is focused
    font-size: 1.4rem;
    font-weight: $type-weight-medium;
    background-color: $white;
    padding: 0 $ui-space-base * 0.5;
    // Other styles
    position: absolute;
    left: 13px;
    top: -13px;
    cursor: text;
    transition: all 0.2s ease;
  }

  label {
    margin: 0px !important;
  }

  // Textfields and selects

  input[type="text"],
  input[type="password"],
  input[type="email"],
  input[type="number"],
  input[type="date"],
  select {
    @include textfield-appearance;

    margin-bottom: 0;

    &::placeholder {
      color: $text-secondary;
      opacity: 1;
      transition: all 0.2s;
    }

    &:placeholder-shown:not(:focus)::placeholder {
      opacity: 0;
    }

    &:placeholder-shown:not(:focus) {
      + .label {
        font-size: inherit;
        font-weight: inherit;
        color: $text-secondary;
        left: 0;
        top: 0;
        padding: $ui-space-base * 1.5 $ui-space-base * 2;
        background-color: transparent;
      }
    }
    &.with-dropdown {
      padding-right: 56px !important;
    }

    &:disabled {
      cursor: not-allowed;
    }
  }

  input[type="search"] {
    @include textfield-appearance;

    margin-bottom: 0;

    padding-right: $ui-space-base * 6;

    &:focus {
      padding-right: (($ui-space-base * 6) - 1);
    }
  }

  select {
    padding-right: $ui-space-base * 5;
    background: url($svg-dropdown-caret);
    background-position: calc(100% - #{$ui-space-base} * 1.5) center;
    background-repeat: no-repeat;

    &:focus {
      padding: 11px $ui-space-base * 5 11px 15px;
    }
  }

  // Accessory for textfields

  .input-accessory {
    position: absolute;
    right: 0;
    top: 0;
    height: 50px;
    padding-left: $ui-space-base * 2;
    padding-right: $ui-space-base * 2;
    display: flex;
    align-items: center;
    appearance: none;
    border: 0;
    background-color: transparent;
    box-sizing: border-box;
    cursor: pointer;
    color: $text-secondary;

    .hide {
      display: none;
    }

    .show {
      display: block;
    }

    svg {
      width: $ui-icon-width;
      height: $ui-icon-width;
      fill: currentColor;
    }
  }

  &.no-margin-bottom {
    margin-bottom: 0;
  }
}

// Checkboxes

:not(.switch) {
  > input[type="checkbox"],
  > input[type="radio"] {
    display: none;

    + label {
      display: inline-block;
      padding-left: $ui-space-base * 5;
      position: relative;
      cursor: pointer;

      &:before {
        content: "";
        box-sizing: border-box;
        width: $ui-space-base * 3;
        height: $ui-space-base * 3;
        border: $input-border-width solid rgba(0, 0, 0, 0.2);
        border-radius: $ui-space-base * 1.5;
        background: #F4F3F6;
        position: absolute;
        left: 0;
        top: 0;
      }
    }

    &:checked + label:before {
      border: 0;
    }
  }

  > input[type="checkbox"]:checked + label:before {
    background-image: url($svg-check-mark);
  }

  > input[type="radio"]:checked + label:before {
    background: none;
    border: 8px solid $electricberry;
  }

  > input[type="radio"]:disabled + label:before {
    background-color: rgba(0,0,0,0.2);
    pointer-events: none;
  }
}

// Buttons

.btn {
  @include type-btn;

  display: block;
  padding: $ui-space-base * 1.5 $ui-space-base * 3;
  color: $white;
  background-color: $electricberry;
  box-sizing: border-box;
  text-align: center;
  border: 0;
  border-radius: $ui-border-radius * 0.5;
  transition: background-color 0.3s ease;
  cursor: pointer;
  outline: none;

  &:hover {
    color: $white;
    background-color: darken($electricberry, 8%);
  }

  &.disabled,
  &[disabled] {
    background-color: $text-secondary;
  }

  &.btn-cta {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: $ui-space-base * 2.5 $ui-space-base * 4;
    text-align: left;
    box-shadow: $shadow-elevation1;
  }

  &.btn-sub {
    color: $text-default;
    background-color: $white;
    border: $ui-border-width solid $ui-border-color;

    @include type-body;

    &:hover {
      background-color: darken($white, 8%);
    }
  }

  &.btn-non-raised {
    color: $text-default;
    background-color: $white;
    box-shadow: none;

    &:hover {
      background-color: darken($white, 8%);
    }
  }

  &.btn-danger {
    background-color: $red;

    &:hover {
      background-color: darken($red, 8%);
    }

    &.disabled,
    &[disabled] {
      background-color: $text-secondary;
    }
  }

  &.btn-link {
    color: $link-default;
    background-color: $white;
    border: $ui-border-width solid $ui-border-color;
    text-decoration: none;

    &:hover {
      background-color: darken($white, 8%);
    }

    &.disabled,
    &[disabled] {
      color: $text-secondary;
    }
  }

  &.btn-small {
    padding: $ui-space-base * 0.5 $ui-space-base * 1.5;
  }

  &.btn-medium {
    padding: $ui-space-base $ui-space-base * 2;
  }

  .btn-secondary {
    color: $text-secondary;
    display: block;

    @include type-symbol;

    text-transform: initial;
  }

  &.btn-transparent {
    color: $electricberry;
    background-color: transparent;
    padding: 0px;
    font-size: 18px;
    font-weight: 500;

    &:hover {
      color: lighten($electricberry, 12%);
    }
  }

  .btn-icon {
    display: inline-block;
    width: $ui-icon-width;
    height: $ui-icon-width;
    flex-grow: 0;

    svg {
      width: $ui-icon-width;
      height: $ui-icon-width;
      fill: currentColor;
    }
  }

  &.btn-sub .btn-icon {
    color: $text-secondary;
  }

  &.btn-small .btn-icon {
    width: 24px;
    height: 24px;
    display: block;

    svg {
      width: 24px;
      height: 24px;
    }
  }

  .btn-label {
    min-width: 67%;
  }

  &.btn-inline {
    display: inline-block;
  }
}

// Flash

.flash-message {
  margin: $ui-space-base * 2 0;
  padding: $ui-space-base * 2 $ui-space-base * 3;
  background-color: $cardboard;
  border-radius: $ui-border-radius;

  &-primary {
    color: $electricberry;
    background-color: lighten($electricberry, 47%);
  }

  &-success {
    color: darken($green, 7%);
    background-color: lighten($green, 50%);
  }

  &-danger {
    color: $red;
    background-color: lighten($red, 48%);
  }
}

// Card and Card Grid

.card {
  @include card-appearance;

  @include media(">tablet") {
    border: 0;
    border-radius: $ui-border-radius;
    overflow: hidden;
    box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.04);
  }

  width: 100%;

  header {
    display: flex;
    align-items: center;
    justify-content: space-between;

    div {
      display: flex;
      align-items: center;
    }

    .btn {
      margin-bottom: 0;

      @include media(">desktop") {
        margin-right: $ui-space-base * 2;
      }
    }

    @include media("<desktop") {
      padding: $ui-space-base * 2;
    }
  }

  table {
    width: 100%;
    border-collapse: collapse;

    thead {
      th {
        @include type-th();

        color: $text-secondary;
      }
    }

    th,
    td {
      padding: $ui-space-base $ui-space-base * 2;
      border-bottom: $ui-border-width solid $ui-border-color;
    }
  }

  footer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: $ui-space-base $ui-space-base * 2;

    @include type-activity;

    a {
      margin-left: $ui-space-base;
      margin-right: $ui-space-base;
    }
  }
}

.card-grid {
  .container {
    @include media("<tablet") {
      padding: 0;
    }
  }

  .card {
    @include media(">tablet") {
      width: calc(50% - #{$ui-space-base * 4});
      margin-left: $ui-space-base * 2;
      margin-right: $ui-space-base * 2;
    }
  }
}

// Filters

.filter-buttons {
  display: flex;
  align-items: center;

  .filter-buttons-label {
    margin-left: $ui-space-base * 4;

    &:first-child {
      margin-left: 0;
    }
  }

  .exposed-buttons {
    display: flex;
    align-items: center;

    @include media("<desktop") {
      display: none;
    }
  }

  .hidden-buttons {
    display: none;
    align-items: center;

    @include media("<desktop") {
      display: flex;
    }
  }

  .btn {
    position: relative;
    display: block;
    text-transform: inherit;
    font-size: $type-btn-size;
    margin-bottom: 0;
    margin-left: 8px;
  }

  .active {
    background-color: $electricberry;
    color: $white;

    &:hover {
      background-color: $electricberry;
    }
  }
}

// Popover

.popover {
  visibility: hidden;
  min-width: 192px;
  background-color: $white;
  border: $ui-border-width solid $ui-border-color;
  border-radius: $ui-border-radius;
  box-shadow: $shadow-elevation1;
  padding-top: $ui-space-base;
  padding-bottom: $ui-space-base;
  z-index: $zindex-popover;

  &.active {
    visibility: visible;
  }

  ul {
    list-style: none;
    padding: 0;
    margin: 0;

    li a {
      display: block;
      padding: $ui-space-base * 0.5 $ui-space-base * 2;
      color: $text-default;

      &:hover {
        background-color: darken($white, 8%);
      }
    }
  }
}

// coin-filter

.coin-filter {
  display: flex;
  flex-direction: row;
  align-items: center;

  .btn {
    color: black;
    width: 200px;
    display: flex;
    align-items: center;

    .btn-icon {
      width: 32px;
      height: 32px;
    }

    b {
      float: left;
      padding: 0 0 0 8px;
      text-transform: none;
    }
  }

  .dropdown__options-container {
    overflow-y: auto;
    width: 200px;
    max-width: 200px !important;
    max-height: 260px !important;

    .dropdown__option {
      display: flex;
      align-items: center;

      .dropdown_icon {
        width: 32px;
        height: 32px;
      }
    }
  }

  .coin_label {
    padding: 0 0 0 8px;
  }

  .fa {
    position: absolute;
    right: 8px;
    top: 12px;
  }
}

// Privacy Policy
.privacy-policy-content {
  ul {
    list-style: none;
    padding-left: 18px;

    li {
      margin: 2rem auto;
    }

    .bullets {
      list-style: square;
    }

    .no-margin {
      margin: 0;
    }
  }
}

.privacy-policy-headings-list {
  list-style: none;
}

.dropdown-options-promo-tag {
  position: relative;
  right: -5px;
  top: -10px;
  color: $electricberry;
  font-size: 12px;
  font-weight: bold;
}

.dropdown-options-rewards-tag {
  position: relative;
  right: -8px;
  background: $electricberry;
  font-size: 12px;
  font-weight: 600;
  color: $white;
  padding: 4px 8px;
  border-radius: 4px;
}
