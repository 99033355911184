.batch-table {
  background-color: #ffffff;
}

// Grable = portmanteau of grid and table
.grable {
  width: 100%;
  border: 1px solid #ccc;
  border-radius: 8px;
}

.grable-header {
  font-size: 14px;
  grid-template-rows: 64px !important;
  @include media("<desktop") {
    display: none !important;
  }
}
// Grable row – equivalent of tr
.gr {
  //position: relative;
  border-bottom: 1px solid #ccc;
  padding: $ui-space-base * 3;
  display: grid;
  grid-template-columns: 56px 64px 84px minmax(100px, 120px) minmax(132px, 1fr) minmax(128px, 1fr) minmax(118px, 1fr) 1fr 1fr minmax(112px , 0.75fr);
  grid-template-rows: 64px;
  font-size: 14px;

  &:last-child {
    border: none;
  }

  @include media("<desktop") {
    display: flex;
    height: auto;
    flex-direction: row;
    flex-wrap: wrap;
  }
}

.positive-number {
  color: #009900;
  //font-size: 14px !important;
}

.negative-number {
  color: #e60000;
  //font-size: 14px !important;
}

.xmas-background {
  background-color: #FAF6E7;
}

.gr-no-border {
  border-bottom: none;
}

.gh {
  font-weight: bold;
}

// Grable header and cell – equivalent of th and td
.gh, .gd {
  display: flex;
  align-items: center;

  @include media(">desktop") {
    flex-grow: 10;
    flex-basis: 80px;
  }
}

.gh:first-child, .gd:first-child {
  @include media(">desktop") {
    flex-basis: 0 !important;
  }
}

.sprod-icon {
  @include media("<desktop") {
    margin-right: $ui-space-base;
    margin-bottom: $ui-space-base * 2;
  }

  .sprod-icon-wrapper {
    width: 32px !important;
    height: 32px !important;
  }
}

.sprod-title {
  margin-bottom: $ui-space-base;

  @include media("<desktop") {
    flex: 0 0 calc(50% - 40px);
    align-items: center;
    //height: 32px;
    margin-bottom: $ui-space-base * 2;
    font-weight: 500;
  }
}

.sprod-date {
  display: inline-block;
  margin-bottom: $ui-space-base;

  @include media("<desktop") {
    display: flex;
    margin-bottom: $ui-space-base * 2;
    color: $text-secondary;
  }
}

.mb-24-m {
  @include media("<desktop") {
    margin-bottom: $ui-space-base * 3;
  }
}

.sprod-value {
  justify-content: space-between;

  @include media("<desktop") {
    flex: 0 0 100%;
    color: $text-secondary;
  }
}

.sprod-progress {
  @include media("<desktop") {
    flex: 0 0 50%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-bottom: $ui-space-base * 2;
  }
}

.sprod-status {
  span {
    font-size: 12px;
  }
  @include media("<desktop") {
    flex: 0 0 50%;
    justify-content: flex-end;

    span {
      font-size: 14px;
    }
  }
}

.sprod-action {
  padding-left: 0px !important;
  padding-right: 0px !important;
  @include media("<desktop") {
    flex: 0 0 50%;
    margin: 0;
  }
}

.batch-progress-bar {
  height: 10px;
  background-color: #ddd;
  border-radius: 6px;
  flex-grow: 1;
  width: 96px;
  margin-top: 0 !important;

  @include media("<desktop") {
    flex: 0 0 100%;
  }
}

.batch-progress-bar .progress-bar {
  background-color: #6400ff;
}

.batch-details {
  margin: 0;
  //height: 32px;
  color: #6400FF;
  background-color: #fff;
  text-decoration: none;
  text-transform: uppercase;
  font-size: 17px;
  font-weight: bold;
  letter-spacing: 0.8px;
  cursor: pointer;
}

.mobile-font {
  @include media("<desktop") {
    font-size: 18px !important;
  }
}

.batch-details-row {
  padding: $ui-space-base * 3;
  border-bottom: 1px solid #ccc;
  font-size: 16px;

  @include media("<desktop") {
    padding-top: 0;

    b {
      font-size: 18px;
    }
  }

  & .entry-details {
    display: block;

    @include media("<desktop") {
      margin-bottom: 2 * $ui-space-base;
    }
  }

  & .entry-details-ongoing-batches {
    border: 1px solid #ccc;
    border-radius: 8px 8px 0 0;
    padding: 2 * $ui-space-base;
  }

  & .entry-details-ongoing-batches .without-compound {
    border-radius: 8px 8px 8px 8px;
  }

  & .coin-entry {
    display: flex;
    justify-content: space-between;
    margin-bottom: 0.5 * $ui-space-base;

    @include media("<desktop") {
      font-size: 18px !important;
      span {
        font-size: 18px !important;
        text-align: end;
      }
    }
  }

  & .batch-trades {
    margin-bottom: 25px;

    & b {
      display: block;
      margin-bottom: 2 * $ui-space-base;
    }

    span , p {
      font-size: 16px;

      @include media("<desktop") {
        font-size: 18px;
      }
    }
  }

  & .batch-activity {
    display: flex;
    justify-content: space-between;
    margin-bottom: 2 * $ui-space-base;
    padding-bottom: 10px;
    border-bottom: 1px solid #ccc;

    & .batch-activity-text {
      font-weight: 500;
      margin-right: 2 * $ui-space-base;
      text-transform: uppercase;
    }

    & .trade-hash-copy {
      cursor: pointer;
    }
  }
}

.batch-details-compound-settings {
  border-top: none;
  border-left: 1px solid #ccc;
  border-right: 1px solid #ccc;
  border-bottom: 1px solid #ccc;
  border-radius: 0 0 8px 8px;
  padding: 2 * $ui-space-base;
  display: block;
  width: 100%;
  margin-bottom: 25px;

  & b {
    display: block;
    margin-bottom: 2 * $ui-space-base;
  }

  & span {
    display: block;
  }

  & div, form {
    width: 100%;
  }

  div {
    margin-bottom: 25px;
  }

  fieldset {
    position: relative;
  }

  input[type="radio"] {
    opacity: 0;
    cursor: pointer;
    display: inline-block;
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 1000;
  }
}

@for $i from 1 through 10 {
  .g-lg-o#{$i} {
    @include media("<desktop") {
      order: $i;
    }
  }
}

@media (max-width: 1200px) {
  @include media(">desktop") {
    .gh, .gd {
      padding: $ui-space-base * 1.5 $ui-space-base * 0.5;
    }
  }
}

@include media(">desktop") {

  .gr {
    padding-top: 0;
    padding-bottom: 0;
  }

  .gh, .gd {
    padding: $ui-space-base * 1.5;
  }

  .g-ar {
    justify-content: flex-end;
    text-align: right;
  }

  .hide-lg {
    display: none;
  }

  .sprod-title,
  .sprod-date {
    margin-bottom: 0;
  }

  .sprod-progress,
  .sprod-status {
    position: static;
  }

  .sprod-status {
    display: flex;
    justify-content: center;
  }

  .sprod-title, .sprod-date {
    display: inherit;
    margin-bottom: 0;
  }

  .batch-details {
    font-size: 14px;
  }

  .batch-details-row {
    & .entry-details-ongoing-batches, .entry-details-past-batches {
      border-radius: 8px 0 0 8px;
      margin-bottom: 25px;
      width: 33.3%;
    }

    .entry-details-past-batches {
      border-radius: 8px 8px 8px 8px;
      border: 1px solid #ccc;

      & .coin-entry {
        padding: 15px;
        margin: 0px;

        &:first-child {
          border-bottom: 1px solid #ccc;
        }
      }
    }

    & .entry-details, .batch-details-compound-settings {
      display: flex;
    }

    .batch-details-compound-settings {
      width: 66.7%;
      border-top: 1px solid #ccc;
      border-left: none;
      border-right: 1px solid #ccc;
      border-bottom: 1px solid #ccc;
      border-radius: 0 8px 8px 0;
    }
  }
}

.sprod-label {
  color: $black;
  font-weight: 500;
}

.compound-settings-selected-text {
  @include media("<desktop") {
    span {
      font-size: 18px;
    }
  }
}

.flex-left-desktop {
  @include media(">desktop") {
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
}

.flex-right-desktop {
  @include media(">desktop") {
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
}

.trade-history-row {
  display: grid;
  grid-template-columns: minmax(100px, 1fr) minmax(88px, 1fr) minmax(75px, 0.75fr) minmax(50px, 0.75fr) minmax(64px, 0.75fr) minmax(100px, 1fr) 1fr 1fr minmax(88px, 1.25fr) minmax(120px, 1.25fr) minmax(128px, 1.25fr) minmax(64px, 0.75fr);
  grid-template-rows: 56px;
  font-size: 14px;
  border-left: 1px solid rgba(0, 0, 0, 0.1);
  border-right: 1px solid rgba(0, 0, 0, 0.1);
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  padding-left: $ui-space-base * 2 !important;
  padding-right: $ui-space-base * 2 !important;

  &:last-child {
    border-radius: 0px 0px $ui-border-radius $ui-border-radius;
  }
  @include media(">desktop") {
    div {
      display: flex;
      justify-content: flex-end;
      align-items: center;
    }
  }


  @include media("<desktop") {
    display: flex;
    height: auto;
    flex-direction: column;
    padding-top: $ui-space-base * 3 !important;
    padding-bottom: $ui-space-base * 3  !important;
    font-size: 18px;

    div span {
      font-size: 18px;
    }
  }
}

.trade-history-header {
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: $ui-border-radius $ui-border-radius 0px 0px;

  @include media("<desktop") {
    display: none !important;
  }
}

.trade-history-table {
  @include media("<desktop") {
    .trade-history-row:nth-child(2) {
      border-top: 1px solid rgba(0, 0, 0, 0.1);
      border-radius: $ui-border-radius $ui-border-radius 0px 0px;
    }
  }
}

.batch-bonus-section {
  margin-bottom: 24px;
  > .row {
    margin-bottom: 32px;

    &:last-child {
      margin-bottom: 0px;
    }
  }
}

.bonus-round {
  margin-bottom: 16px;
}

.batch-bonus-section-text {
  padding-top: 8px;
  padding-bottom: 8px;
  color: $grey;

  > p {
    font-size: 16px;
    font-weight: 500;
  }
}


.batch-bonus-card {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 16px;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 8px;
  height: 100%;
  width: 100%;

  :first-child() {
    font-size: 14px;
  }

  :nth-child(2) {
    margin-top: 4px;
    margin-bottom: 44px;
    font-weight: bold;
    font-size: 20px;
  }

  :nth-child(3) {
    font-size: 16px;
    color: $grey;
  }
}

.level-reached {
  background-color: $electricberry;

  * {
    color: white !important;
  }
}

.batch-bonus-col {
  padding-top: 8px;
  padding-bottom: 8px;
}

.batch-bonus {
  font-weight: bold;
  margin-bottom: 16px;
}

.batch-bonus-current-price {
  margin-bottom: 32px;
}

.bonus-settlement {
  display: flex;
  justify-content: flex-start;
  margin-left: -12px;
  margin-right: -12px;
  margin-top: 8px;
  margin-bottom: 24px;
  flex-wrap: wrap;

  @include media("<tablet") {
    flex-direction: column;
  }

  > div {
    display: flex;
    flex-direction: column;
    padding: 8px 12px;
  }
}
