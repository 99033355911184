.video-thumbnail {
  position: relative;

  &-icon-wrapper {
    width: 25px;
    height: 25px;
    position: absolute;
    left: 50%;
    margin-left: -12.5px;
    top: 50%;
    margin-top: -12.5px;
  }
}