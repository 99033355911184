.cakepool-spinner {
  position: fixed;
  top: 50%;
  width: 100%;
  text-align: center;
}

.cakepool-alert {
  .cakepool-alert__container {
    display: flex;
    align-items: center;
    width: 100%;
    border-radius: $ui-border-radius;
    padding: 8px 16px;
    text-align: center;

    .cakepool-alert {
      &--success {
        background-color: $green;
        color: white;
      }
      &--danger {
        background-color: $red;
        color: white;
      }
      &--warning {
        background-color: $orange;
        color: white;
      }
      &--primary {
        background-color: $electricberry;
        color: white;
      }
    }
  }
}

// Video Modal
.video-modal-wrapper {
  position: relative;
  padding-bottom: 56.25%;
  height: 0;

  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}