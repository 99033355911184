.rewards-page {
  .rewards-page__title {
    padding-left: 64px;
  }
}

.rewards-card {
  article{
    margin-bottom: 0;
  }
  .deposit-withdrawal-collapsible {
    max-height: 0;
    overflow: hidden;
    transition: 0.2s;
    padding-left: 26px;
    padding-right: 26px;
    border-bottom: 0.5px solid rgba(0, 0, 0, 0.16);
    &-content {
      display: grid;
      grid-template-columns: 1fr 5fr;
      -webkit-align-items: center;
      align-items: center;
      padding: 8px 0;
    }
    &.collapsible-show{
      max-height: 300px;
      padding-top: 16px;
      padding-bottom: 16px;
    }
  }
  .icon-small{
    cursor: pointer;
  }
}