.datepicker-input-group {
  display: block;
  font-size: 16px;

  .react-datepicker {
    font-size: 0.8em;

    &__time-container, &__time-box {
      width: 110px !important;
    }

    &__time-list-item {
      font-size: 0.8em;
    }

    &__input-container {
      display: block;
    }

    &__navigation--next--with-time:not(.react-datepicker__navigation--next--with-today-button) {
      right: 120px;
    }

    &__day--selected, &__day--in-selecting-range, &__day--in-range, &__month-text--selected, &__month-text--in-selecting-range, &__month-text--in-range, &__day--keyboard-selected, &__month-text--keyboard-selected {
      background-color: $electricberry;
    }
    
  }

  @include media("<tablet") {
    font-size: 14px;

    .react-datepicker {
      font-size: 0.8em;

      &__time-container, &__time-box {
        width: 90px !important;
      }

      &__navigation--next--with-time:not(.react-datepicker__navigation--next--with-today-button) {
        right: 100px;
      }
  
      &__time-list-item {
        font-size: 0.7em;
      }
    }
  }
  * {
    cursor: pointer;
  }
}
