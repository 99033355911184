.icon__container {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  .icon__image {
    width: 100%;
    height: 100%;
  }
}

.info-icon {
  margin-left: 8px;
  margin-top: -2px;
  fill: gray;
}

.info-icon-inline {
  margin-left: 4px;
  fill: gray;
  display: inline;
  > div {
    display: inline;
  }
}

.list-icon {
  svg {
    width: 16px !important;
    color: rgba(0, 0, 0, 0.6) !important;
  }
}

.list-icon.active {
  svg {
    color: $electricberry !important;
  }
}

.copy-icon {
  cursor: pointer;

  svg {
    width: 24px !important;
    height: 24px !important;
    fill: $grey;
  }
}

.check-icon {
  margin-left: 4px;
  width: 24px !important;
  height: 24px !important;

  svg {
    fill: $green;
  }
}

.error-icon {
  margin-left: 4px;
  width: 24px !important;
  height: 24px !important;
  
  svg {
    fill: $red;
  }
}

.warning-icon {
  svg {
    height: 24px !important;
    height: 24px !important;
    fill: #FF9629;
  }
}