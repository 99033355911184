.terms-and-conditions {

  h4 {
    font-size: 1.6rem !important;
  }

  ol {
    counter-reset: item;
    padding-left: 16px;
  }
  li {
      display: block;
  }
  
  li li:before {
      content: counters(item, ".") " ";
      counter-increment: item;
  }
  
  .mb-24 {
    margin-bottom: 2.4rem;
  }

  .mt-24 {
    margin-top: 2.4rem;
  }

  h2, h3 {
    display: inline-block;
  }

  li {
    font-size: 2.4rem;
    font-weight: bold;
    li {
      font-size: 1.6rem;
      font-weight: normal;
    }
  }
  
  ol ol ol ol {
    counter-reset: none;
    padding-left: 40px;
    content: normal;
    li:before {
      content: initial !important;
    } 
    li {
       display: list-item;
    }
  }

  .reset {
    counter-reset: none;
    padding-left: 40px;
    content: normal;
    li:before {
      content: initial !important;
    } 
    li {
       display: list-item;
    }
  }

  .risk-warning {
    padding-left: 16px;
    
    h2:before {
      content: initial;
    }

    li {
      font-size: 1.6rem;
      font-weight: normal;
    }
  }
}

.terms-and-conditions > ol > li {
  margin-bottom: 24px;
}