.feature-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 80px;
  height: 80px;
  background-color: white;
  border: .5px solid rgba(0,0,0,.16);
  background-color: #fff;
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.04);
  border-radius: 8px;
}