.trade-history-wrapper {
  overflow-x: scroll;

  .table {
    margin-bottom: 0;
    border: 1px solid rgba(0, 0, 0, 0.1);

    th, td {
      border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    }
  }
}

.load-more-button {
  color: #6400FF;
  padding: 10px;
  text-align: center;
  font-size: 14px;
  font-weight: 500;
  text-transform: uppercase;

  @include media("<desktop") {
    border: 1px solid #ccc;
    border-radius: $ui-border-radius;
    margin-top: $ui-space-base * 2;
    padding: $ui-space-base * 2;
    background-color: white;

    span {
      color: #6400FF;
      text-decoration: none;
      font-size: 17px !important;
      letter-spacing: 0.8px;
    }
  }

  span {
    cursor: pointer;
    font-size: 14px;
  }
}

.trade-history {
  th {
    color: #000000 !important;
    text-transform: none !important;
  }

  th, td {
    padding: 15px 20px !important;
    text-align: right;
    font-size: 14px !important;
  }

  span {
    font-size: 14px !important;
  }

  .details {
    color: #6400FF;
    background-color: #fff;
    text-decoration: none;
    text-transform: uppercase;
    font-weight: bold;

    span {
      cursor: pointer;
      font-size: 14px !important;
    }
  }

  .batch-completed {
    color: #009900;
  }

  .trade-details-row {
    display: none;

    span {
      font-size: 14px !important;
    }
  }

  .progress {
    border: 0.5px solid rgba(0, 0, 0, 0.16);
    border-radius: 10px;
  }

  .progress-bar {
    background-color: #6400ff;
  }

  .trade-details-entry {
    display: flex;
    justify-content: flex-start;

    .trade-details-entry-amount {
      text-align: left;
      border-left: 1px solid rgba(0, 0, 0, 0.16);
      border-top: 1px solid rgba(0, 0, 0, 0.16);
      border-bottom: 1px solid rgba(0, 0, 0, 0.16);
      border-right: 1px solid rgba(0, 0, 0, 0.16);
      width: 50%;
    }

    .entry-ongoing-trades {
      display: flex;
      justify-content: space-between;
      padding: 15px;
      border-radius: 10px 0 0 10px;
    }

    .entry-ongoing-trades-without-compound {
      display: flex;
      justify-content: space-between;
      padding: 15px;
      border-radius: 10px 10px 10px 10px;
      width: 33.3%;
    }

    .entry-past-trades {
      border-radius: 10px 10px 10px 10px;
      width: 33.3%;
      .entry-amount {
        display: flex;
        justify-content: space-between;
        height: 50%;
        padding: 15px;
      }

      .actual-return {
        display: flex;
        justify-content: space-between;
        border-top: 1px solid rgba(0, 0, 0, 0.16);
        height: 50%;
        padding: 15px;
      }
    }

    .trade-details-compound-settings {
      text-align: left;
      display: flex;
      border-top: 1px solid rgba(0, 0, 0, 0.16);
      border-right: 1px solid rgba(0, 0, 0, 0.16);
      border-bottom: 1px solid rgba(0, 0, 0, 0.16);
      border-radius: 0 10px 10px 0;
      width: 100%;
      padding: 15px;

      h4 {
        margin-bottom: 20px;
      }

      span {
        display: block;
      }

      div {
        width: 100%;
      }

      form {
        width: 100%;
      }

      input[type="radio"] {
        opacity: 0;
        cursor: pointer;
        display: block;
        position: absolute;
        width: 100%;
        height: 100%;
        z-index: 1000;
      }
    }
  }

  .trade-hashes, .settlement-notice {
    display: flex;
    justify-content: space-between;
  }

  .settlement-notice {
    padding-top: 10px;
    margin-bottom: 15px;
  }

  .trade-hash {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid rgba(0, 0, 0, 0.16);
    padding-bottom: 10px;
    margin-bottom: 10px;

    .trade-hash-proof {
      @include media("<desktop") {
        max-width: 100%;
      }
      span {
        font-weight: bold;
        margin-right: 5px;
      }
    }

    .trade-hash-copy {
      cursor: pointer;
    }
  }
}

.batch-settlement-notice {
  font-size: 16px;
  @include media("<desktop") {
    span {
      font-size: 18px !important;
    }
  }
}

.trade-hash-display {
  margin: 0;
  word-wrap: break-word;
  font-weight: normal !important;
}

.max-width-100-pc {
  @include media("<desktop") {
    max-width: calc(100% - 16px);
  }
}

.batch-detail-popover {
  padding: 10px;
}

.hedge-hashes {
  display: flex;
  justify-content: flex-start;
  margin-top: 40px;
}

.settled-hedge-activities {
  margin-top: 15px;
  list-style: none;
  float: left;
  padding-left: 0;
}

.settled-hedge-activities li {
  display: flex;
}

.settled-hedge-activities span {
  margin-left: 5px;
  margin-right: 5px;
}

.trades-no-hashes span {
  margin-top: 15px;
  margin-bottom: 15px;
}

.batch-progress-bar {
  margin-top: 7px;
}

.proof-modal {
  padding: 25px;

  & h1 {
    margin-bottom: 75px;
  }

  & h3 {
    margin-top: 45px;
    margin-bottom: 30px;
  }

  & hr {
    margin-top: 25px;
    margin-bottom: 25px;
  }

  & .proof-trade-data, & .proof-published-hash {
    overflow-wrap: break-word;
  }

  & .hashing-tool {
    @media (max-width: 1024px) {
      margin-bottom: 24px;
    }
  }

  & a {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;

    @media (max-width: 600px) {
      height: 100px;
      width: 100%;
    }
  }

  & img {
    width: 66.7%;

    @media (max-width: 600px) {
      width: 33.3%;
    }
  }

  & .copy-icon {
    cursor: pointer;
  }
}

.proof-icon {
  transform: scale(2);
  margin-top: 10px;
  padding-right: 15px;
  display: flex;
  justify-content: flex-end;
}

.proof-modal-footer {
  background-color: #f4f3f7;
  height: 128px;
  margin-top: 8px;
  display: flex;
  justify-content: flex-end;
  align-items: center;

  & span {
    text-transform: uppercase;
    cursor: pointer;
    margin-right: 25px;
    font-weight: bold;
    color: #6400ff;
  }
}

.activity-info .injected-svg {
  cursor: pointer;
}

.hashing-website-tooltip {
  padding: 10px;
}

.table td {
  border-top: none !important;
}

.card table .no-border-row td {
  border-bottom: none !important;
}

.batch-details-popover {
  padding: 4px 16px;
}