.tnc-content {
  >div {
    max-height: 60vh;
    overflow-y: scroll;
  }

  >div>ol>li {
    font-weight: bold;

    >ol {
      padding-inline-start: 20px;
    }
  }

  ul {
    list-style-type: disc;
  }

  li {
    padding: 8px;
  }

  footer {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding-top: 32px;
  }

  @include media("<tablet") {
    ol {}
  }
}


.register__promo-code-input {
  width: 100%;
  padding-right: 120px;
  position: relative;
}

.register__promo-code-button {
  width: 100%;
  margin-top: -12px;
}

.register-confirmation {
  padding-bottom: 64px !important;
}