// Colors

$electricberry:                 #6400FF;
$cardboard:                     #F4F3F7;
$black:                         #000;
$faded:                         rgba($black,.16);
$white:                         #fff;
$green:                         #00E676;
$red:                           #F50057;
$orange:                        #FF9800;
$highlightyellow:               #fcffa8;
$light-grey:                    #B3B0BB;
$light-red:                     #FF0000;
$grey:                          rgba($black,0.6);
$lavender:                      #E8E5F8;

// Text colors

$text-default:                  $black;
$text-secondary:                rgba($black,.6);
$link-default:                  $electricberry;

// UI base dimension and multiples

$ui-space-base:                 8px;

$ui-border-width:               0.5px;
$ui-border-color:               $faded;
$ui-border-color-light:         $light-grey;
$ui-border-radius:              $ui-space-base;

// Borders and separators

$input-border-width:            1px;
$input-focus-border-width:      2px;

// Main header

$main-header-height:            $ui-space-base * 8;

// Switch

$switch-height:                 $ui-space-base * 2;
$switch-width:                  $switch-height * 2.5;
$switch-overhang:               $switch-height * 0.5;

// Oven specs

$oven-cell-height:              $ui-space-base * 8;
$oven-cell-height-desktop:      $ui-space-base * 12;

// Icons

$ui-icon-width:                 $ui-space-base * 4;

// Typography

$type-base-family:              'IBM Plex Sans',
                                -apple-system,
                                BlinkMacSystemFont,
                                "Segoe UI",
                                Roboto,
                                Helvetica,
                                Arial,
                                sans-serif,
                                "Apple Color Emoji",  /* Emojis*/
                                "Segoe UI Emoji",     /* Emojis*/
                                "Segoe UI Symbol";    /* Emojis*/

$type-heading-family:           'IBM Plex Sans',
                                -apple-system,
                                BlinkMacSystemFont,
                                "Segoe UI",
                                Roboto,
                                Helvetica,
                                Arial,
                                sans-serif,
                                "Apple Color Emoji",  /* Emojis*/
                                "Segoe UI Emoji",     /* Emojis*/
                                "Segoe UI Symbol";    /* Emojis*/

$type-mono-family:              'IBM Plex Mono',
                                "SFMono-Regular",
                                Consolas,
                                "Liberation Mono",
                                Menlo,
                                Courier,
                                monospace;

$type-weight-regular:           400;
$type-weight-text:              400; //450 doesn't work in Firefox
$type-weight-medium:            500;
$type-weight-semibold:          600;
$type-weight-bold:              700;

$type-logo-size:                2.0rem;
$type-logo-weight:              $type-weight-medium;
$type-logo-line-height:         3.2rem;
$type-logo-letter-spacing:      tracking-to-ems(0);
$type-logo-margin:              0;

$type-h0-size:                  4.0rem;
$type-h0-weight:                $type-weight-text;
$type-h0-line-height:           4.8rem;
$type-h0-letter-spacing:        tracking-to-ems(0);
$type-h0-margin:                0 0 3.2rem;

$type-h1-size:                  3.2rem;
$type-h1-weight:                $type-weight-semibold;
$type-h1-line-height:           4.8rem;
$type-h1-letter-spacing:        tracking-to-ems(0);
$type-h1-margin:                0 0 3.2rem;

$type-h2-size:                  2.4rem;
$type-h2-weight:                $type-weight-semibold;
$type-h2-line-height:           3.2rem;
$type-h2-letter-spacing:        tracking-to-ems(0);
$type-h2-margin:                0 0 3.2rem;

$type-h3-size:                  2.0rem;
$type-h3-weight:                $type-weight-semibold;
$type-h3-line-height:           2.4rem;
$type-h3-letter-spacing:        tracking-to-ems(2);
$type-h3-margin:                0 0 2.4rem;

$type-lead-size:                2.0rem;
$type-lead-weight:              $type-weight-text;
$type-lead-line-height:         2.4rem;
$type-lead-letter-spacing:      tracking-to-ems(0);
$type-lead-margin:              0;

$type-body-size:                1.6rem;
$type-body-weight:              $type-weight-text;
$type-body-line-height:         2.4rem;
$type-body-letter-spacing:      tracking-to-ems(0);
$type-body-margin:              0 0 2.4rem;

$type-th-size:                  1.4rem;
$type-th-weight:                $type-weight-semibold;
$type-th-line-height:           1.6rem;
$type-th-letter-spacing:        tracking-to-ems(0);
$type-th-margin:                0;

$type-btn-size:                 1.6rem;
$type-btn-weight:               $type-weight-semibold;
$type-btn-line-height:          2.4rem;
$type-btn-letter-spacing:       tracking-to-ems(0);
$type-btn-margin:               0;

$type-activity-size:            1.6rem;
$type-activity-weight:          $type-weight-text;
$type-activity-line-height:     3.2rem;
$type-activity-letter-spacing:  tracking-to-ems(0);
$type-activity-margin:          0;

$type-symbol-size:              1.6rem;
$type-symbol-weight:            $type-weight-medium;
$type-symbol-line-height:       2.4rem;
$type-symbol-letter-spacing:    tracking-to-ems(0);
$type-symbol-margin:            0;


// Shadows

$shadow-elevation1:             0 1px 2px 0 rgba($black, 0.16);
$shadow-elevation2:             0 2px 8px 0 rgba($black, 0.8), 0 4px 16px 0 rgba($black, 0.08);
$shadow-elevation3:             0 4px 16px 0 rgba($black, 0.08), 0 8px 32px 0 rgba($black, 0.08);


// Z-indices

$zindex-dropdown:               1000;
$zindex-sticky:                 1020;
$zindex-fixed:                  1030;
$zindex-modal-backdrop:         1040;
$zindex-modal:                  1050;
$zindex-popover:                1060;
$zindex-tooltip:                1070;
$zIndex-wallet-summary:         1080;


// Media Query

$breakpoints:                   (
                                phone: 374px,
                                tablet: 767px,
                                desktop: 1023px,
                                hd: 1439px,
                                uhd: 1919px,
                                bsm: 576px,
                                bmd: 768px,
                                blg: 992px,
                                bxl: 1200px
                                );

$media-expressions:             (
                                'screen': 'screen',
                                'print': 'print',
                                'handheld': 'handheld',
                                'landscape': '(orientation: landscape)',
                                'portrait': '(orientation: portrait)',
                                'retina2x': '(-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi)',
                                'retina3x': '(-webkit-min-device-pixel-ratio: 3), (min-resolution: 350dpi)'
                                );
