.floating {
  margin-bottom: $ui-space-base * 8;
}

.cakepool-spinner{
  top: 6%;
  z-index: 1042;
}

body > #root > footer{
  margin-top: 32px;
}

.welcome-page {
  .floating {
    margin-bottom: 0;
  }
}