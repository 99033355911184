.datetext-wrapper {
  margin-bottom: 4rem;
}

.datetext {
  display:flex;
  flex-wrap:wrap;
  flex-direction:row;
  max-height: 50px;
}

.month-input {
  padding-right: 0% !important;
  padding-bottom: 0% !important;
  padding-left: 10% !important;
  height: 50px;
  background-color: white;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 4px 4px;
  align-items: center;
}

.day {
  width: 7%;
  min-width: 60px;
  -webkit-appearance: none;
  -moz-appearance: textfield !important;
}

.month {
  width: 15%;
  min-width: 130px;
}

.year {
  width: 10%;
  min-width: 80px;
  -webkit-appearance: none;
  -moz-appearance: textfield !important;
}

.margin-date {
  margin-left: 1%;
}

.helper-text-padding {
  padding-bottom: 3%;
}

.date-text-label {
  font-size: 1.4rem;
  font-weight: 500;
}

input:focus::-webkit-input-placeholder {
    color: #888;
}