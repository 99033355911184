.cakepool-modal {
  position: fixed;
  height: 100vh;
  width: 100vw;
  top: 0;
  left: 0;
  z-index: $zindex-modal;

  .modal-overlay {
    display: block;
  }

  .modal-content {
    min-height: 300px;
    border: none;
  }

  .close-button {
    cursor: pointer;
  }

  .modal-padding {
    padding: 20px;
  }

  @include media("<desktop") {
    padding-bottom: 60px;
  }
}