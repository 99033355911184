.cakepool-tooltip {
  position: fixed;
  display: inline-flex;
  background-color: $white;
  border: $ui-border-width solid $ui-border-color;
  border-radius: $ui-border-radius;
  box-shadow: $shadow-elevation1;
  padding-top: $ui-space-base;
  padding-bottom: $ui-space-base;
  z-index: $zindex-popover;
}

.cakepool-tooltip-close-button {
  display: flex;
  justify-content: flex-end;
  color: #6c757d;
  padding-right: 15px;
  width: 40px;
  height: 16px;

  & i {
    cursor: pointer;
  }
}
