.defrost-amount {
  display: flex;
  align-items: flex-start;

  .input-group {
    margin-right: 2 * $ui-space-base !important;
    margin-bottom: 3 * $ui-space-base !important;
  }
}

.defrostable-amounts {
  padding: 16px 0px;
}

.defrost-modal-balance {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px 0px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);

  &:last-child {
    border-bottom: 0px;
  }

  :nth-child(2) {
    color: $grey;
  }
}